<template>
  <NuxtLayout>
    <NuxtPwaManifest />
    <NuxtPage />
    <div class="slick-list-target z-index-top position-absolute" />
    <reusable-notification v-if="notificationConfig" v-model="showNotification" :config="notificationConfig" />
    <reusable-prompt v-for="prompt in promptQueue" :key="prompt.id" v-bind="prompt" />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NotificationConfig, PromptConfig } from '~/types/Reusable'

useSecurityStore()
useSettingsStore()
useDashboardsStore()
useLinkedDashboardsStore()
useDevicesStore()
useThemeStore()

const { $eventBus } = useNuxtApp()
const notificationConfig = ref<NotificationConfig>()
const showNotification = ref(false)
const promptQueue = ref<PromptConfig<Record<string, never>>[]>([])
$eventBus.on('show-prompt', (event) => {
  promptQueue.value.push(event)
})
$eventBus.on('hide-prompt', () => {
  promptQueue.value.shift()
})
$eventBus.on('show-notification', (event) => {
  showNotification.value = false
  nextTick(() => {
    notificationConfig.value = event
    showNotification.value = true
  })
})
$eventBus.on('hide-notification', () => {
  showNotification.value = false
})
</script>

<style lang="css">
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>

<style lang="scss">
@use "~/assets/utils.scss";
@use "~/assets/font.scss";
@use "~/assets/overwrites.scss";
</style>
