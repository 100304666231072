import { DateTime } from 'luxon'
import { useField } from 'vee-validate'
import * as zod from 'zod'

type i18nStaticKey = `$t(${string})`
type zodMessageBody = {
  code: i18nStaticKey
  params: unknown[]
}
function isZodMessageBody(e: unknown): e is zodMessageBody {
  return typeof e !== 'string'
}
export const convertToZodMessage: zod.ZodErrorMap = (issue: zod.ZodIssueOptionalMessage, _ctx: zod.ErrorMapCtx) => {
  const messageBody: zodMessageBody = {
    code: `$t(VALIDATION.${issue.code})`,
    params: [],
  }
  switch (issue.code) {
    case 'invalid_type': {
      messageBody.code = '$t(VALIDATION.invalid_type)'
      messageBody.params.push(issue.expected)
      messageBody.params.push(issue.received)
      break
    }
    case 'invalid_literal':{
      messageBody.code = '$t(VALIDATION.invalid_literal)'
      messageBody.params.push(issue.expected, issue.received)
      break
    }
    case 'unrecognized_keys':{
      messageBody.code = '$t(VALIDATION.unrecognized_keys)'
      messageBody.params.push(issue.keys)
      break
    }
    case 'invalid_union':{
      messageBody.code = '$t(VALIDATION.invalid_union)'
      messageBody.params.push(issue.unionErrors)
      break
    }
    case 'invalid_union_discriminator':{
      messageBody.code = '$t(VALIDATION.invalid_union_discriminator)'
      // TODO: recursicve parsing
      break
    }
    case 'invalid_enum_value':{
      messageBody.code = '$t(VALIDATION.invalid_enum_value)'
      messageBody.params.push(...issue.options, issue.received)
      break
    }
    case 'invalid_arguments':{
      messageBody.code = '$t(VALIDATION.invalid_arguments)'
      // TODO: recursicve parsing
      break
    }
    case 'invalid_return_type':{
      messageBody.code = '$t(VALIDATION.invalid_return_type)'
      // TODO: recursicve parsing
      break
    }
    case 'invalid_date':{
      messageBody.code = '$t(VALIDATION.invalid_date)'
      break
    }
    case 'invalid_string':{
      messageBody.code = '$t(VALIDATION.invalid_string)'
      messageBody.params.push(issue.validation)
      break
    }
    case 'too_small':{
      messageBody.code = '$t(VALIDATION.too_small)'
      messageBody.params.push(issue.minimum)
      break
    }
    case 'too_big':{
      messageBody.code = '$t(VALIDATION.too_big)'
      messageBody.params.push(issue.maximum)
      break
    }
    case 'invalid_intersection_types':{
      messageBody.code = '$t(VALIDATION.invalid_intersection_types)'
      break
    }
    case 'not_multiple_of':{
      messageBody.code = '$t(VALIDATION.not_multiple_of)'
      messageBody.params.push(issue.multipleOf)
      break
    }
    case 'not_finite':{
      messageBody.code = '$t(VALIDATION.not_finite)'
      break
    }
    case 'custom':{
      console.warn('Custom error encountered', issue.params)
      if (issue.message) {
        messageBody.code = issue.message as i18nStaticKey
        if (issue.params) {
          Object.keys(issue.params).forEach((k) => {
            messageBody.params.push(issue.params![k])
          })
        }
      }
      break
    }
  }
  return { message: messageBody as unknown as string }
}
zod.setErrorMap(convertToZodMessage)

function extractStaticTranslation(token: `$t(${string})`) {
  return token.substring(3, token.length - 1)
}

function translateField(errors: MaybeRefOrGetter<string[]>, label: i18nStaticKey) {
  const { $i18n } = useNuxtApp()
  const errorsRef = toRef(errors)
  const translatedField = computed(() => {
    return $i18n.t(extractStaticTranslation(label))
  })
  const translatedErrors = computed(() => {
    return errorsRef.value.map((e) => {
      if (isZodMessageBody(e)) {
        return $i18n.t(extractStaticTranslation(e.code), [translatedField.value, ...e.params])
      }
      return e
    })
  })
  return { translatedField, translatedErrors }
}

/**
 * Wrapper over vee-validate's useField. It will clear the path and return the props, errors and model.
 * The returned props are adjusted for vuetify components and can be used using v-bind
 * @param isSwitch - for v-switch the default color must be the one from the theme(not the validation one).
 * If path is taken directly from props it will not be reactive
 */
export function useValidation<TValue = unknown>(path: MaybeRefOrGetter<string>, isSwitch: boolean = false) {
  const computedPath = computed(() => {
    const original = toValue(path)
    if (original.startsWith('.')) {
      return original.replace('.', '')
    }
    return original
  })
  const { value, errors, meta, setErrors, validate } = useField<TValue>(computedPath)
  const { translatedErrors } = translateField(errors, '$t(INPUT_FIELD.DEFAULT)')
  const props = computed(() => ({
    'error-messages': translatedErrors.value,
    'base-color': meta.valid
      ? isSwitch ? '' : 'success'
      : '',
    'color': meta.valid ? 'success' : 'error',
  }))
  return { value, errors: translatedErrors, props, setErrors, validate }
}
export function useValidationI18n<TValue = unknown>(path: MaybeRefOrGetter<string>, label: i18nStaticKey, isSwitch: boolean = false) {
  const computedPath = computed(() => {
    const original = toValue(path)
    if (original.startsWith('.')) {
      return original.replace('.', '')
    }
    return original
  })
  const { value, errors, meta, setErrors, validate } = useField<TValue>(computedPath)
  const { translatedField, translatedErrors } = translateField(errors, label)
  const props = computed(() => ({
    'error-messages': translatedErrors.value,
    'base-color': meta.valid
      ? isSwitch ? '' : 'success'
      : '',
    'color': meta.valid ? 'success' : 'error',
    'label': translatedField.value,
  }))
  return { value, errors: translatedErrors, props, setErrors, validate }
}
export function ISODateValidator(input: string, ctx: zod.RefinementCtx): void {
  if (!DateTime.fromISO(input).isValid) {
    const issue: zod.ZodIssueOptionalMessage = {
      code: 'invalid_date',
      path: ctx.path,
    }
    const message = convertToZodMessage(issue, { defaultError: '', data: input })
    ctx.addIssue({
      code: 'custom',
      message: message.message,
    })
    // return zod.NEVER
  }
}
/**
 * Expects an ISO 8601 date format. Any error message will output the current user selected format
 */
export function DateTimeValidator(input: string, ctx: zod.RefinementCtx): void {
  const settingsStore = useSettingsStore()
  if (!DateTime.fromISO(input).isValid) {
    const issue: zod.ZodIssueOptionalMessage = {
      code: 'custom',
      message: '$t(VALIDATION.dateTime.invalidFormat)',
      path: ctx.path,
      params: {
        format: settingsStore.personalSettings.dateFormat,
      },
    }
    const body = convertToZodMessage(issue, { defaultError: '', data: input })
    ctx.addIssue({
      code: 'custom',
      message: body.message,
    })
    return zod.NEVER
  }
}
