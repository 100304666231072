import { useAsyncState, whenever } from '@vueuse/core'
import { defineStore } from 'pinia'
import type { LinkedDashboard } from '~/types/Dashboards'

export const useLinkedDashboardsStore = defineStore('LinkedDashboards', () => {
  const securityStore = useSecurityStore()
  const { isLoggedIn } = storeToRefs(securityStore)
  const request = ref(Promise.resolve<LinkedDashboard[]>([]))
  const controller = useAsyncState(() => findLinkedDashboards().then(r => r.data), [], { immediate: false, shallow: true })
  const isLoading = controller.isLoading
  const { state: linkedDashboards, update: updateLinkedDashboards } = useStateMutation(controller.state)

  function load() {
    if (!isLoading.value) {
      request.value = controller.execute()
    }
  }
  function unload() {
    request.value = Promise.resolve([])
    controller.state.value = []
  }

  function addDashboardLink(token: string) {
    return linkDashboard(token).then((r) => { // link dashboard
      return findLinkedDashboardByLinkedTokenId(r.data.id) // get the data back
    }).then((r) => {
      updateLinkedDashboards((linkedDashboards) => {
        linkedDashboards.push(r.data)
      })
      return r.data
    })
  }
  function removeDashboardLink(token: string) {
    return unlinkDashboard(token).then(() => {
      updateLinkedDashboards((linkedDashboards) => {
        const index = linkedDashboards.findIndex(d => d.token.id === token)
        if (index >= 0) {
          linkedDashboards.splice(index, 1)
        }
      })
    })
  }
  whenever(isLoggedIn, load, { immediate: true })
  whenever(() => !isLoggedIn.value, unload)
  return {
    isLoading,
    request,
    linkedDashboards,
    addDashboardLink,
    removeDashboardLink,
  }
})
