import { produce } from 'immer'
import type { ShallowRef } from 'vue'

type Update<T> = (input: T) => void

/**
 * Use this to apply changes to large immutable data
 * @see https://vuejs.org/guide/extras/reactivity-in-depth#immutable-data
 * @see https://immerjs.github.io/immer/
 */
export const useStateMutation = <T>(state: ShallowRef<T> | Ref<T>) => {
  /**
   * @param updater The update callback, everything inside it that's assigned to the current state
   * MUST be a raw value and not a proxy. In case of ref values use {@link toRaw} to get the value behind proxy
   * @see https://immerjs.github.io/immer/pitfalls/
   */
  function update(updater: Update<T>) {
    state.value = produce(state.value, updater)
  }
  return {
    state, update,
  }
}
