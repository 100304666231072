import * as zod from 'zod'

export const SortDirection = zod.union([
  zod.literal('ASC'),
  zod.literal('DESC'),
  zod.literal('NONE'),
])
export type SortDirection = zod.infer<typeof SortDirection>
export type Arrayable<T> = T | T[]
/**
 * Checks whenever an array is non empty and contains at least one element
 */
export function isNonEmpty<A>(arr: Array<A>): arr is [A, ...A[]] {
  return arr.length > 0
}
/**
 * Marks all properties of T as nullable
 * To convert it back use NonNullable<T>
 */
export type Nullable<T> = {
  [K in keyof T]: Nullable<T[K]> | null
}

export type Undefined<T> = T | undefined

/**
 * Works in similar ways to {@link AtLeast}, but required only one property from the targt type
 */
export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U]
/**
 * Marks the given properties as required
 * @param T - the target type
 * @param K - any key or union of keys from T
 */
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
export interface FieldEntry<TValue = unknown> {
  value: TValue
  key: string | number
  isFirst: boolean
  isLast: boolean
}
