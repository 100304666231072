<template>
  <v-snackbar v-model="model" :timeout="config.timeout" variant="outlined" :color="config.type" :content-class="bg" @mouseleave="startProgress" @mouseenter="stopProgress">
    <v-progress-linear :model-value="progress" :indeterminate="indeterminate" :color="config.type" absolute location="top" />
    <div>{{ config.title }}</div>
    <div>{{ config.text }}</div>
    <div v-if="!indeterminate" class="d-flex justify-end pt-3">
      <v-btn variant="text" :color="config.type" @click="model = false">Close</v-btn>
    </div>
  </v-snackbar>
</template>

<script lang="ts" setup>
import type { NotificationConfig } from '~/types/Reusable'

const model = defineModel<boolean>()
const { config } = defineProps<{
  config: NotificationConfig
}>()
const progressOff = ref(false)
const poolingRate = 100
const currentTime = ref(0)
const currentTimeout = ref()
const { current } = useTheme()
const bg = computed(() => current.value.dark ? 'bg-grey-darken-4' : 'bg-white')
const indeterminate = computed(() => config.timeout === -1 ? true : undefined)
const progress = computed(() => {
  if (indeterminate.value) {
    return undefined
  }
  return 100 - Math.ceil((100 * currentTime.value) / config.timeout)
})
const syncProgress = () => {
  currentTimeout.value = setTimeout(() => {
    if (!progressOff.value) {
      currentTime.value += poolingRate
      if (config.timeout >= currentTime.value) {
        syncProgress()
      }
    }
  }, poolingRate)
}
const stopProgress = () => {
  progressOff.value = true
}
const startProgress = () => {
  progressOff.value = false
  syncProgress()
}
const reset = () => {
  currentTime.value = 0
  if (currentTimeout.value) {
    clearTimeout(currentTimeout.value)
  }
}
watch(model, (n, _o) => {
  reset()
  if (n && !indeterminate.value) {
    startProgress()
  } else {
    stopProgress()
  }
})
</script>

<style>

</style>
