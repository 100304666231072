import { useAsyncState, whenever } from '@vueuse/core'
import mitt from 'mitt'
import { defineStore } from 'pinia'
import type { AccountSettings, DashboardSortingSettings, DeviceSortingSettings, SortingConfiguration, ThemeSettings } from '~/types/AccountSettings'
import type { DateFormat, Language, PersonalSettings } from '~/types/PersonalSettings'
import { THEME_FONTS, THEME_SIZES } from '~/types/Theme'

type Events = {
  languageUpdate: Language
}

export const useSettingsStore = defineStore('Settings', () => {
  const { emit, on, off } = mitt<Events>()
  const { $i18n } = useNuxtApp()
  const domain = ref(window.location.host.split('.')[0])
  const defaultThemeSettings: ThemeSettings = {
    themeSize: 'NORMAL',
    themeFont: 'ROBOTO',
  }
  const defaultPersonalSettings: PersonalSettings = {
    email: null,
    dateFormat: 'dd-LL-yyyy HH:mm',
    language: {
      code: 'EN',
      label: 'English',
    },
    showDevicesSearchBar: false,
    showHexacodesAsDeviceNames: false,
    showDepartmentNumbers: false,
    showNetworkControllers: false,
    enablePageRefreshOnRequest: false,
    enableFactorySettingsLines: false,
    defaultPcNumber: 1,
    pcNumbers: [1],
    minBufferInterval: 288,
    use2FA: false,
    showAllPagesEnabled: false,
    enableAlarmEmailExport: false,
    alarmEmailExportAddresses: [],
  }
  const defaultDeviceSortingSettings: DeviceSortingSettings = {
    sortMode: 'NAME',
    sortDirection: 'ASC',
  }
  const defaultDashboardSortingSettings: DashboardSortingSettings = {
    sortMode: 'CUSTOM',
    sortDirection: 'ASC',
  }
  const defaultSettings: AccountSettings = {
    ...defaultThemeSettings,
    ...defaultPersonalSettings,
    deviceConfigurationSettings: defaultDeviceSortingSettings,
    dashboardsSettings: defaultDashboardSortingSettings,
  }
  const securityStore = useSecurityStore()
  const { isLoggedIn } = storeToRefs(securityStore)
  const request = ref(Promise.resolve(defaultSettings))
  const controller = useAsyncState(() => findAccountSettings().then(r => processSettings(r.data)), defaultSettings, { immediate: false, shallow: true })
  const themeSettings = ref<ThemeSettings>(defaultThemeSettings)
  const personalSettings = ref<PersonalSettings>(defaultPersonalSettings)
  const deviceSortingSettings = ref<DeviceSortingSettings>(defaultDeviceSortingSettings)
  const dashboardSortingSettings = ref<DashboardSortingSettings>(defaultDashboardSortingSettings)
  const isLoading = controller.isLoading
  const availablePcNumbers = computed(() => {
    let pcNumbers = personalSettings.value.pcNumbers
    if (securityStore.permissions.APPLY_ACL.WRITE()) {
      pcNumbers = Array.from({ length: 99 }, (value, index) => index + 1)
    }
    return pcNumbers
  })
  const headbarHeight = computed(() => THEME_SIZES[themeSettings.value.themeSize].headbarHeight)
  function processSettings(input: AccountSettings) {
    input.dateFormat = (input.dateFormat
      .replaceAll('DD', 'dd')
      .replaceAll('MM', 'LL')
      .replaceAll('YYYY', 'yyyy')
      .replaceAll('hh', 'HH')
    ) as DateFormat
    // set the refs
    deviceSortingSettings.value = input.deviceConfigurationSettings
    dashboardSortingSettings.value = input.dashboardsSettings

    const personalSettingsKeys = Object.keys(defaultPersonalSettings) as (keyof AccountSettings)[]
    personalSettings.value = pick(input, ...personalSettingsKeys)

    const themeSettingsKeys = Object.keys(defaultThemeSettings) as (keyof ThemeSettings)[]
    themeSettings.value = pick(input, ...themeSettingsKeys)
    createRootRule()
    return input
  }
  function load() {
    if (!isLoading.value) {
      request.value = controller.execute()
    }
  }
  function unload() {
    request.value = Promise.resolve(defaultSettings)
    deviceSortingSettings.value = defaultDeviceSortingSettings
    dashboardSortingSettings.value = defaultDashboardSortingSettings
    personalSettings.value = defaultPersonalSettings
    themeSettings.value = defaultThemeSettings
  }
  function saveAccountSettings(body: PersonalSettings): Promise<PersonalSettings> {
    return updateAccountSettings(body)
      .then((r) => {
        personalSettings.value = r.data
        emit('languageUpdate', personalSettings.value.language)
        return personalSettings.value
      })
  }
  function saveThemeSettings(body: ThemeSettings): Promise<void> {
    return submitThemeSettings(body).then(() => {
      themeSettings.value = body
      createRootRule()
    })
  }
  function updateDeviceSortingSettings(sortingConfiguration: SortingConfiguration) {
    deviceSortingSettings.value = sortingConfiguration
  }
  function createRootRule() {
    const fontData = THEME_FONTS[themeSettings.value.themeFont]
    const sizeData = THEME_SIZES[themeSettings.value.themeSize]

    // we must add the ratio here as the icons are always bigger than text
    const iconRatio = 24 / 16
    // const iconDenseRation = 20 / 16

    fontData.importRule()
    let element = document.getElementById('variables')
    if (element === null) {
      element = document.createElement('style')
      element.id = 'variables'
      document.head.appendChild(element)
    }
    const styleSheet = (element as HTMLStyleElement).sheet
    if (styleSheet !== null) {
      while (styleSheet.cssRules.length > 0) {
        styleSheet.deleteRule(0)
      }
      const rule = `:root {
        --font-family: ${fontData.fontFamily} !important;
        --font-size: ${sizeData.fontSize}px !important;
        --o-icon-size-multiplier: ${iconRatio}px !important;
        --o-table-row-height: ${sizeData.tableRowHeight}px !important;
        --o-table-header-height: ${sizeData.tableHeaderHeight}px !important;
        --v-list-indent-size: ${sizeData.listIndentSize}px !important;
        --v-list-item-padding-x: ${sizeData.listItemPaddingX}px !important;
        --v-list-item-padding-y: ${sizeData.listItemPaddingY}px !important;
        --v-list-item-two-line-padding-x: ${sizeData.listItemTwoLinePaddingX}px !important;
        --v-list-item-two-line-padding-y: ${sizeData.listItemTwoLinePaddingY}px !important;
        --v-list-item-three-line-padding-x: ${sizeData.listItemThreeLinePaddingX}px !important;
        --v-list-item-three-line-padding-y: ${sizeData.listItemThreeLinePaddingY}px !important;
        --v-list-item-min-height: ${sizeData.listItemMinHeight}px !important;
        --v-list-item-one-line-min-height: ${sizeData.listItemOneLineMinHeight}px !important;
        --v-list-item-two-line-min-height: ${sizeData.listItemTwoLineMinHeight}px !important;
        --v-list-item-three-line-min-height: ${sizeData.listItemThreeLineMinHeight}px !important;
        --o-field-control-underlined-height: ${sizeData.fieldControlUnderlinedHeight}px !important;
        --o-button-height: ${sizeData.buttonHeight}px !important;
        --o-date-picker-month-day-size: ${sizeData.datePickerMonthDaySize}px !important;
        --o-date-picker-month-btn-height: ${sizeData.datePickerMonthBtnHeight}px !important;
      }`
      styleSheet.insertRule(rule, 0)
    }
  }
  whenever(isLoggedIn, load, { immediate: true })
  whenever(() => !isLoggedIn.value, unload)
  watch(personalSettings, n => $i18n.setLocale(n.language.code))
  return {
    domain,
    availablePcNumbers,
    isLoading,
    request,
    headbarHeight,
    themeSettings,
    personalSettings,
    deviceSortingSettings,
    dashboardSortingSettings,
    load, // TODO - maybe remove them(loading it's happening based on user token from security store)
    unload,
    saveAccountSettings,
    saveThemeSettings,
    updateDeviceSortingSettings,
    on,
    off,
  }
})
