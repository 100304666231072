<template>
  <v-dialog v-model="model" max-width="600" scrollable persistent opacity="0.65">
    <v-card>
      <v-form @submit.prevent="onSubmit">
        <v-card-title>{{ title }}</v-card-title>
        <v-divider class="mx-4" />
        <v-card-text>
          <h1 v-if="type === 'simple'" class="text-center text-h6">{{ question }}</h1>
          <h1 v-if="type === 'confirmation'" class="text-center text-h6">{{ question }}</h1>
          <v-text-field
            v-if="type === 'text'"
            v-model="inputValue"
            :label="label"
            v-bind="inputValueAttrs"
          />
          <v-autocomplete
            v-if="type === 'select'"
            v-model="inputValue"
            :label="label"
            :items="items"
            :item-title="itemTitle"
            :item-value="itemValue"
            return-object
            v-bind="inputValueAttrs"
          />
        </v-card-text>
        <v-card-actions class="d-flex">
          <div v-if="type !== 'simple'" class="w-100 pr-1">
            <v-btn block color="accent" variant="outlined" @click="cancel">{{ cancelText }}</v-btn>
          </div>
          <div class="w-100 pl-1">
            <v-btn block color="primary" type="submit" variant="outlined">{{ confirmText }}</v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup generic="T">
import * as zod from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import type { PromptProperties } from '~/types/Reusable'

const model = ref(false)
const {
  id,
  type,
  question,
  label,
  initialValue,
  rules,
  cancelText: cancelTextValue,
  confirmText: confirmTextValue,
  items,
  itemTitle,
  itemValue,
} = defineProps<PromptProperties<T>>()
const { t } = useI18n()
const { $eventBus } = useNuxtApp()
const confirmText = computed(() => {
  if (confirmTextValue) {
    return confirmTextValue
  }
  return t('ConfirmationDialog.YES_MESSAGE')
})
const cancelText = computed(() => {
  if (cancelTextValue) {
    return cancelTextValue
  }
  return t('ConfirmationDialog.NO_MESSAGE')
})
const validationSchema = computed(() => {
  const inputValueRules = rules ? rules : zod.unknown().optional()
  return toTypedSchema(zod.object({
    inputValue: inputValueRules,
  }))
})
const { handleSubmit, setFieldValue } = useForm({
  validationSchema,
  initialValues: {
    inputValue: initialValue,
  },
})
watchEffect(() => {
  if (id) {
    setFieldValue('inputValue', initialValue)
  }
})
const { value: inputValue, props: inputValueAttrs } = useValidation<T>('inputValue')
const onSubmit = handleSubmit((values) => {
  model.value = false
  $eventBus.emit('hide-prompt', {
    id: id,
    value: type === 'confirmation' ? true : values.inputValue,
  })
})
const cancel = () => {
  model.value = false
  $eventBus.emit('hide-prompt', {
    id: id,
    cancelled: true,
  })
}
onMounted(() => model.value = true)
</script>

<style>

</style>
