import type { SortDirection, SortMode } from '~/types/AccountSettings'
import type { DeviceUnion } from '~/types/DeviceConfiguration'

export function DEVICE_NAME_COMPARATOR_ASC(l: DeviceUnion, r: DeviceUnion) {
  const leftValue = l.configuration.label != null ? l.configuration.label : l.name
  const rightValue = r.configuration.label != null ? r.configuration.label : r.name
  return leftValue.toLocaleLowerCase().localeCompare(rightValue.toLocaleLowerCase())
}
export function DEVICE_NAME_COMPARATOR_DESC(l: DeviceUnion, r: DeviceUnion) {
  const leftValue = l.configuration.label != null ? l.configuration.label : l.name
  const rightValue = r.configuration.label != null ? r.configuration.label : r.name
  return rightValue.toLocaleLowerCase().localeCompare(leftValue.toLocaleLowerCase())
}
export function DEVICE_CUSTOM_COMPARATOR(l: DeviceUnion, r: DeviceUnion) {
  const leftValue = l.configuration.order
  const rightValue = r.configuration.order
  return leftValue - rightValue
}
export function sortDevices(devices: DeviceUnion[], sortMode: SortMode, sortDirection?: SortDirection) {
  let comparator = DEVICE_CUSTOM_COMPARATOR
  if (sortMode === 'NAME') {
    comparator = DEVICE_NAME_COMPARATOR_ASC
    if (sortDirection === 'DESC') {
      comparator = DEVICE_NAME_COMPARATOR_DESC
    }
  }
  devices.sort(comparator)
}
export function filterDevices<T extends DeviceUnion>(devices: T[], searchHint: string | null): T[] {
  if (searchHint) {
    const hint = searchHint.toLowerCase()
    return devices.filter(d =>
      d.name.toLocaleLowerCase().indexOf(hint) >= 0
      || d.hexacode.toLowerCase().indexOf(hint) >= 0
    )
  }
  return devices
}
