import { useAsyncState, whenever } from '@vueuse/core'
import mitt from 'mitt'
import { defineStore } from 'pinia'
import type { DashboardSortingSettings } from '~/types/AccountSettings'
import { hasMarkableAreaSupport, type Dashboard, type DashboardAndGroupsUnion, type DashboardsSortAndGroupData, type WidgetMarkAreaElement } from '~/types/Dashboards'
import { isNonEmpty } from '~/types/Utils'

type Events = {
  favoriteDashboardUpdate: string
  sortingSettingsChanged: Record<string, number>
}

export const WIDGET_REFRESH_INTERVAL = 60000
export const useDashboardsStore = defineStore('Dashboards', () => {
  const { emit, on, off } = mitt<Events>()
  const securityStore = useSecurityStore()
  const { isLoggedIn } = storeToRefs(securityStore)
  const settingsStore = useSettingsStore()
  const inEditMode = ref(false)
  const request = ref(Promise.resolve<DashboardAndGroupsUnion[]>([]))
  const controller = useAsyncState(() => findDashboards().then(r => sortDashboardsAndGroups(r.data, settingsStore.dashboardSortingSettings)), [], { immediate: false, shallow: true })
  const isLoading = controller.isLoading
  const { state: dashboardsAndGroups, update: updateDashboardsAndGroups } = useStateMutation(controller.state)
  const dashboards = computed(() => {
    const results: Dashboard[] = []
    dashboardsAndGroups.value.forEach((e) => {
      if (e.type === 'Dashboard') {
        results.push(e)
      } else {
        results.push(...e.dashboards)
      }
    })
    return results
  })
  const favoriteDashboard = computed(() => dashboards.value.find(d => d.favorite))
  const maxOrder = computed(() => dashboardsAndGroups.value.reduce((a, v) => v.order > a ? v.order : a, 0))
  function load() {
    if (!isLoading.value) {
      request.value = controller.execute()
    }
  }
  function unload() {
    request.value = Promise.resolve([])
    dashboardsAndGroups.value = []
  }
  function createDashboard(name: string) {
    const dashboard: Dashboard = {
      type: 'Dashboard',
      name: name,
      order: maxOrder.value + 1,
      favorite: !favoriteDashboard.value,
      breakpoint: 'xl',
      groupId: null,
      shared: false,
      widgets: [],
    }
    return saveDashboard(dashboard).then((r) => {
      updateDashboardsAndGroups((dashboardsAndGroups) => {
        dashboardsAndGroups.push(r.data)
      })
      inEditMode.value = true
      return r.data
    })
  }
  function updateDashboardData(dashboard: Dashboard) {
    if (!dashboard.id) {
      dashboard.order = maxOrder.value + 1
      dashboard.favorite = !favoriteDashboard.value
    }
    return updateDashboard(dashboard).then((r) => {
      updateDashboardsAndGroups((dashboardsAndGroups) => {
        if (!dashboard.id) {
          dashboardsAndGroups.push(r.data)
        } else {
          for (let i = 0; i < dashboardsAndGroups.length; i++) {
            const entry = dashboardsAndGroups[i]
            if (entry.type === 'Dashboard' && entry.id === r.data.id) {
              dashboardsAndGroups[i] = r.data
              break
            } else if (entry.type === 'DashboardsGroupData') {
              const index = entry.dashboards.findIndex(d => d.id === r.data.id)
              if (index >= 0) {
                entry.dashboards[index] = r.data
                break
              }
            }
          }
        }
      })
      return r.data
    })
  }
  function linkDashboard(dashboard: Dashboard) {
    dashboard.favorite = false
    updateDashboardsAndGroups((dashboardsAndGroups) => {
      dashboardsAndGroups.push(dashboard)
    })
  }
  function removeDashboard(id: string) {
    return deleteDashboard(id).then(() => {
      updateDashboardsAndGroups((dashboardsAndGroups) => {
        const index = dashboardsAndGroups.findIndex(e => e.type === 'Dashboard' && e.id === id)
        if (index >= 0) {
          dashboardsAndGroups.splice(index, 1)
        } else {
          dashboardsAndGroups.forEach((e) => {
            if (e.type === 'DashboardsGroupData') {
              const i = e.dashboards.findIndex(d => d.id === id)
              if (i >= 0) {
                e.dashboards.splice(i, 1)
              }
            }
          })
        }
      })
    })
  }
  function updateFavorite(dashboardId: string) {
    return setFavorite(dashboardId).then(() => {
      updateDashboardsAndGroups((dashboardsAndGroups) => {
        dashboardsAndGroups.forEach((e) => {
          if (e.type === 'Dashboard') {
            if (e.favorite) {
              e.favorite = false
            }
            if (e.id === dashboardId) {
              e.favorite = true
            }
          }
          if (e.type === 'DashboardsGroupData') {
            e.dashboards.forEach((d) => {
              d.favorite = false
              if (d.id === dashboardId) {
                d.favorite = true
              }
            })
          }
        })
      })
      emit('favoriteDashboardUpdate', dashboardId)
    })
  }
  function updateSortingAndGrouping(input: DashboardsSortAndGroupData) {
    updateDashboardsAndGroups((dashboardsAndGroups) => {
      input.removed.forEach((g) => {
        const matchedIndex = dashboardsAndGroups.findIndex(e => e.id === g.group.id)
        if (matchedIndex >= 0) {
          const matched = dashboardsAndGroups[matchedIndex]
          if (matched.type === 'DashboardsGroupData') {
            dashboardsAndGroups.splice(matchedIndex, 1)
            // push back the dashboards with the correct order set
            matched.dashboards.forEach((d) => {
              d.groupId = null
              d.order = input.dashboards[d.id!] || -1
              dashboardsAndGroups.push(d)
            })
          }
        }
      })
      // push back all the dashboards, unset group id and set the correct order
      input.updated.forEach((g) => {
        const matchedIndex = dashboardsAndGroups.findIndex(e => e.id === g.group.id)
        if (matchedIndex >= 0) {
          const matched = dashboardsAndGroups[matchedIndex]
          if (matched.type === 'DashboardsGroupData') {
            matched.dashboards.forEach((d) => {
              d.groupId = null
              d.order = input.dashboards[d.id!] || -1
              dashboardsAndGroups.push(d)
            })
          }
        }
      })
      input.updated.forEach((g) => {
        const matchedIndex = dashboardsAndGroups.findIndex(e => e.id === g.group.id)
        if (matchedIndex >= 0) {
          const matched = dashboardsAndGroups[matchedIndex]
          if (matched.type === 'DashboardsGroupData') {
            const linkedDashboards: Dashboard[] = []
            matched.order = g.group.order
            g.linkedDashboards.forEach((dashboardId) => {
              const matchedDashboardIndex = dashboardsAndGroups.findIndex(e => e.id === dashboardId)
              if (matchedDashboardIndex >= 0) {
                const matchedDashboard = dashboardsAndGroups[matchedDashboardIndex]
                if (matchedDashboard.type === 'Dashboard') {
                  dashboardsAndGroups.splice(matchedDashboardIndex, 1)
                  matchedDashboard.groupId = matched.id!
                  linkedDashboards.push(matchedDashboard)
                }
              }
            })
            if (isNonEmpty(linkedDashboards)) {
              matched.dashboards = linkedDashboards
            }
          }
        }
      })
      input.added.forEach((g) => {
        const groupDashboards = g.linkedDashboards.map((dashboardId) => {
          const matchedDashboardIndex = dashboardsAndGroups.findIndex(e => e.id === dashboardId)
          if (matchedDashboardIndex >= 0) {
            const matchedDashboard = dashboardsAndGroups[matchedDashboardIndex]
            if (matchedDashboard.type === 'Dashboard') {
              dashboardsAndGroups.splice(matchedDashboardIndex, 1)
              matchedDashboard.groupId = g.group.id!
              return matchedDashboard
            }
          }
          return null
        }).filter(d => d !== null)
        if (isNonEmpty(groupDashboards)) {
          const group: DashboardAndGroupsUnion = {
            type: 'DashboardsGroupData',
            id: g.group.id,
            name: g.group.name,
            order: g.group.order,
            dashboards: groupDashboards,
          }
          dashboardsAndGroups.push(group)
        }
      })
      dashboardsAndGroups.forEach((d) => {
        if (d.type === 'Dashboard') {
          d.order = input.dashboards[d.id!] || -1
        }
      })
      sortDashboardsAndGroups(dashboardsAndGroups, input.sortSettings)
    })
    emit('sortingSettingsChanged', input.dashboards)
  }
  function updateMarkAreaElements(elements: WidgetMarkAreaElement[], dashboardId: string, widgetId: string) {
    return updateMarkArea(elements, dashboardId, widgetId).then((r) => {
      const dashboard = dashboards.value.find(d => d.id === dashboardId)
      const widget = dashboard?.widgets.find(w => w.id === widgetId)
      if (widget && hasMarkableAreaSupport(widget)) {
        widget.markAreaElements = r.data
      }
      return r.data
    })
  }
  function sortDashboardsAndGroups(input: DashboardAndGroupsUnion[], sortSettings: DashboardSortingSettings) {
    let comparator = (l: DashboardAndGroupsUnion, r: DashboardAndGroupsUnion) => l.order - r.order
    if (sortSettings.sortMode === 'NAME') {
      const order = sortSettings.sortDirection === 'ASC' ? 1 : -1
      comparator = (l: DashboardAndGroupsUnion, r: DashboardAndGroupsUnion) => l.name.localeCompare(r.name) * order
    }
    input.sort(comparator)
    input.forEach((e, i) => {
      e.order = i
      if (e.type === 'DashboardsGroupData') {
        e.dashboards.sort(comparator).forEach((d, di) => d.order = di)
      }
    })
    return input
  }

  whenever(isLoggedIn, load, { immediate: true })
  whenever(() => !isLoggedIn.value, unload)
  return {
    dashboards,
    dashboardsAndGroups,
    favoriteDashboard,
    isLoading,
    request,
    inEditMode,
    load, // TODO - maybe remove them(loading it's happening based on user token from security store)
    unload,
    updateFavorite,
    createDashboard,
    linkDashboard,
    updateDashboard: updateDashboardData,
    removeDashboard,
    updateMarkAreaElements,
    updateSortingAndGrouping,
    on,
    off,
  }
})
