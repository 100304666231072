import type { ExchangeMap, Request, Response } from '~/types/WebWorker'

const worker = new Worker(new URL('../worker.ts', import.meta.url), { type: 'module' })
export default defineNuxtPlugin((_nuxtApp) => {
  const securityStore = useSecurityStore()
  const { apiToken } = storeToRefs(securityStore)
  const requestQueue = ref<string[]>([])
  watch(apiToken, (n) => {
    const message: Request<'broadcast'> = {
      id: crypto.randomUUID(),
      type: 'broadcast',
      body: {
        type: 'authentication',
        body: {
          token: n,
          uri: window.origin,
        },
      },
    }
    broadcast(message)
  }, { immediate: true })
  function createPromise<K extends keyof ExchangeMap>(request: Request<K>, timeout: number): Promise<Response<K>> {
    const promise = new Promise<Response<K>>((resolve, reject) => {
      let timeoutId = undefined
      if (timeout > 0) {
        timeoutId = setTimeout(() => {
          reject('Timeout')
        }, timeout)
      }
      const errorHandler = (event: ErrorEvent) => {
        clearTimeout(timeoutId)
        worker.removeEventListener('message', handler)
        reject(event.message)
      }
      const handler = (event: MessageEvent<Response<K>>) => {
        if (event.data.id === request.id) {
          clearTimeout(timeoutId)
          worker.removeEventListener('message', handler)
          worker.removeEventListener('error', errorHandler)
          if (event.data.type === 'error') {
            reject(event.data.body)
          } else {
            resolve(event.data)
          }
        }
      }
      worker.addEventListener('message', handler)
      worker.addEventListener('error', errorHandler, { once: true })
    })
    return promise
  }
  function send<K extends keyof ExchangeMap>(request: Request<K>, timeout: number = 30000): Promise<Response<K>> {
    const req = makeClone(request) // strip everything that's not clonable
    const promise = createPromise(request, timeout)
    requestQueue.value.push(request.id)
    worker.postMessage(req)
    return promise
  }
  function broadcast(message: Request<'broadcast'>) {
    const request = makeClone(message) // strip everything that's not clonable
    worker.postMessage(request)
  }
  return {
    provide: {
      webWorker: {
        worker,
        send,
        broadcast,
      },
    },
  }
})
