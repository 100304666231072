import * as zod from 'zod'
import type { DeviceConfiguration } from './DeviceConfiguration'

export type DeviceStatus = 'ON' | 'ON_ALARM' | 'OFF' | 'OFF_ALARM'
export type DeviceType = 'BUSINESS_GROUP' | 'REAL_DEVICE' | 'VIRTUAL_DEVICE' | 'DEPARTMENT'

const DeviceBufferStatus = zod.union([
  zod.literal('SYNCED'),
  zod.literal('NOT_SYNCED'),
  zod.literal('FAILED_SYNC'),
  zod.literal('CREATE_BUFFER'),
  zod.literal('DELETE_BUFFER'),
  zod.literal('RESYNC_BUFFER'),
])
export type DeviceBufferStatus = zod.infer<typeof DeviceBufferStatus>

const DeviceBufferHeadSchema = zod.object({
  department: zod.number(),
  subdepartment: zod.number(),
  page: zod.number(),
  line: zod.number(),
})
// type DeviceBufferHead = zod.infer<typeof DeviceBufferHead>

export const DeviceBufferSchema = zod.object({
  id: zod.string().nullable(),
  hexacode: zod.string(),
  pcNumber: zod.number(),
  start: zod.number(),
  interval: zod.number(),
  storEnabled: zod.boolean().default(false),
  status: DeviceBufferStatus.default('CREATE_BUFFER'),
  head: DeviceBufferHeadSchema,
})
export type DeviceBuffer = zod.infer<typeof DeviceBufferSchema>
export const DeviceBufferFormSchema = zod.object({ buffer: DeviceBufferSchema })
export type DeviceBufferForm = zod.infer<typeof DeviceBufferFormSchema>

export type DataStatus = 'OK' | 'DEVICE_NOT_FOUND' | 'DEVICE_CAN_RECEIVED' | 'ADF_NOT_FOUND' | 'UNAUTHORIZED' |
  'LINE_NOT_PRESENT' | 'LINE_NOT_PRESENT_IN_ADF' | 'DEPARTMENT_NOT_PRESENT' | 'PAGE_NOT_PRESENT' | 'NO_DATA' |
  'ERROR' | 'BUFFER_NOT_PRESENT' | 'DEVICE_READ_ONLY' | 'DEVICE_OFFLINE' | 'DEVICE_TIMEOUT' | 'DATA_NOT_FOUND' | 'DEPARTMENT_OFFLINE'

export const DEFAULT_BUFFER_INTERVALS = [
  { title: '1 Minute', value: 1440 },
  { title: '5 Minutes', value: 288 },
  { title: '15 Minutes', value: 96 },
  { title: '30 Minutes', value: 48 },
  { title: '1 Hour', value: 24 },
  { title: '2 Hours', value: 12 },
  { title: '3 Hours', value: 8 },
  { title: '4 Hours', value: 6 },
  { title: '6 Hours', value: 4 },
  { title: '8 Hours', value: 3 },
  { title: '12 Hours', value: 2 },
  { title: '24 Hours', value: 1 },
]
export const DepartmentPageLineOptionsSchema = zod.object({
  index: zod.string(),
  label: zod.string(),
})
export type DepartmentPageLineOptions = zod.infer<typeof DepartmentPageLineOptionsSchema>
export interface AbstractDepartmentPageLine {
  hexacode: string
  department: number
  subDepartment: number
  pageNumber: number
  lineNumber: number
  name: string
  unit: string
  value: string
  status: DataStatus
  readOnly?: boolean
  readOnlyDevice: boolean
  spaceOptionEnabled: boolean
  options: DepartmentPageLineOptions[]
}
export interface DepartmentPageLine extends AbstractDepartmentPageLine {
  // uuid: string
  // rawHexacode: number
  // customName: string
  // defaultLineValue: string
  // isVisible: boolean
  availableBuffers: DeviceBuffer[]
}
export type DepartmentPage = {
  number: number
  name: string
  visible: boolean
  firstIndex: number
  secondIndex: number
  hasBuffers: boolean
  readOnlyDevice: boolean
  // local
  lines: DepartmentPageLine[]
  linesLoadingTime: number
  loading: boolean
}
export type Department = {
  id: string
  hexacode: string
  rawHexacode: number
  name: string
  status: DeviceStatus
  key: string
  configuration: DeviceConfiguration
  department: number
  subDepartment: number
  mappingHexacode: string
  rawMappingHexacode: number
  adf: string
  networkController: boolean
  // local
  pages: DepartmentPage[]
  pagesLoadingTime: number
  loading: boolean
}
export interface Device {
  id: string
  hexacode: string
  networkNumber?: number
  rawHexacode: number
  mappingHexacode: string
  rawMappingHexacode: number
  name: string
  status: DeviceStatus
  type: DeviceType
  key: string | null
  configuration: DeviceConfiguration
  departments: Department[]
  // local
  translationsLoadingTime: number
  loading: boolean
}
export interface BusinessGroup extends Device {
  devices: Device[]
}
export type DepartmentNameRequest = {
  key: string
  adf: string
  hexacode: string
  department: number
  subDepartment: number
}
export type DepartmentNameResponse = {
  key: string
  label: string
}
export type DepartmentPageRequest = {
  adf: string
  hexacode: string
  rawHexacode: number
  department: number
  subDepartment: number
}
export type DepartmentPageResponse = Omit<DepartmentPage, 'lines'>
export type DepartmentPageLinesRequest = DepartmentPageRequest | {
  page: number
  withBuffers?: boolean
}
export type DepartmentPageLinesResponse = DepartmentPageLine
export type DepartmentPageLineUpdateRequest = {
  hexacode: string
  department: number
  subDepartment: number
  page: number
  line: number
  value: string
}
