export const AVAILABLE_GRANTS = [
  'SYSTEM',
  'MFA_REQUIRED',
  'SECURITY', 'APPLY_ACL',
  'ACCOUNTS',
  'DEVICES', 'DEVICE_TAGS', 'DEVICE_INFO_EXTENDED', 'DEVICE_BLACKLIST', 'DEVICE_MAP', 'DEVICE_ASSIGNED_CLIENTS', 'DEVICE_EXPORT', 'DEVICE_CONTROL_PANEL',
  'ADF_FILES', 'DEALERS', 'SUBDEALERS', 'DOMAINS', 'BUFFERS',
  'EXPORT_MONITOR', 'FTP_ACCOUNTS', 'MAIL_ACCOUNTS', 'SUBSCRIPTION_PLANS', 'ACCESS_GROUPS',
  'SUPPORT', 'VIRTUAL_DEVICES',
  'EXPORT_TEMPLATES', 'RND',
] as const
type GrantTuple = typeof AVAILABLE_GRANTS
export type Grant = GrantTuple[number]
export type GrantType = 'WRITE' | 'READ' | 'DENY'
export type GrantRequirements = 'ALL' | 'ANY'
export type GrantTypePriority = Record<GrantType, number>
export const GrantPriority: GrantTypePriority = {
  DENY: 0,
  READ: 1,
  WRITE: 2,
}
export type GrantCallback = () => boolean
export type GrantPermission = Record<GrantType, GrantCallback>
export type Grants = Record<Grant, GrantType>
export type Permissions = Record<Grant, GrantPermission>

export interface GrantedAuthority {
  authority: string | Grant
}
export interface DomainGrantedAuthority extends Omit<GrantedAuthority, 'authority'> {
  authority: string
  domain: string
}
export interface OptilinkGrantedAuthority extends Omit<GrantedAuthority, 'authority'> {
  authority: `GRANT_${Grant}`
  type: GrantType
}
export interface ApiToken {
  createdAt: string
  expiresAt: string
  domain: string
  id: string
  user: string
  authorities: GrantedAuthority[]
}
export interface LoginAsApiToken extends ApiToken {
  source: ApiToken
}
export interface VapidSubscription {
  endpoint: string
  keys: {
    auth: string
    p256dh: string
  }
  expirationDate: string | null
}
export interface ApiTokenMetadata {
  defaultPcNumber: number
  languageCode: string
  minBufferInterval: number
  pcNumbers: number[]
  username: string
  vapidSubscription: VapidSubscription | null
}
export interface PaginationRequest<T> {
  pageNumber: number
  pageLength: number
  draw: number
  searchHint?: string
  sortField: string
  sortDirection: 'ASC' | 'DESC'
  includeFields?: (keyof T)[]
}
export interface PaginationResponse<T> {
  draw: number
  recordsTotal: number
  recordsFiltered: number
  data: T[]
}
export type Account = {
  id: string
  username: string
  email: string
  roles: string[]
  domains: string[]
}
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface AccountsPaginationRequest extends PaginationRequest<Account> {

}
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface AccountsPaginationResponse extends PaginationResponse<Account> {

}
