import * as zod from 'zod'

const ThemeSizeSchema = zod.union([
  zod.literal('EXTRA_SMALL'), zod.literal('SMALL'), zod.literal('NORMAL'), zod.literal('LARGE'), zod.literal('EXTRA_LARGE'),
])
export type ThemeSize = zod.infer<typeof ThemeSizeSchema>

const ThemeFontSchema = zod.union([
  zod.literal('ROBOTO'), zod.literal('OPEN_SANS'), zod.literal('LATO'), zod.literal('MONTSERRAT'), zod.literal('OSWALD'),
  zod.literal('SOURCE_SANS_PRO'), zod.literal('SLABO_27PX'), zod.literal('RALEWAY'), zod.literal('PT_SANS'), zod.literal('MERRIWEATHER'),
  zod.literal('NUNITO_SANS'), zod.literal('PROMPT'), zod.literal('WORK_SANS'),
])
export type ThemeFont = zod.infer<typeof ThemeFontSchema>

export const ThemeSettingsSchema = zod.object({
  font: ThemeFontSchema.default('ROBOTO'),
  size: ThemeSizeSchema.default('NORMAL'),
})
// export type ThemeSettings = zod.infer<typeof ThemeSettingsSchema>

type SizeConfig = {
  headbarHeight: number
  fontSize: number
  tableRowHeight: number
  tableHeaderHeight: number
  listIndentSize: number
  listItemPaddingX: number
  listItemPaddingY: number
  listItemTwoLinePaddingX: number
  listItemTwoLinePaddingY: number
  listItemThreeLinePaddingX: number
  listItemThreeLinePaddingY: number
  listItemMinHeight: number
  listItemOneLineMinHeight: number
  listItemTwoLineMinHeight: number
  listItemThreeLineMinHeight: number
  fieldControlUnderlinedHeight: number
  buttonHeight: number
  datePickerMonthDaySize: number
  datePickerMonthBtnHeight: number
}
export const THEME_SIZES: Record<ThemeSize, SizeConfig> = {
  EXTRA_SMALL: {
    headbarHeight: 38,
    fontSize: 12,
    tableRowHeight: 38,
    tableHeaderHeight: 42,
    listIndentSize: 16,
    listItemPaddingX: 16,
    listItemPaddingY: 4,
    listItemTwoLinePaddingX: 16,
    listItemTwoLinePaddingY: 4,
    listItemThreeLinePaddingX: 16,
    listItemThreeLinePaddingY: 16,
    listItemMinHeight: 38,
    listItemOneLineMinHeight: 38,
    listItemTwoLineMinHeight: 38,
    listItemThreeLineMinHeight: 48,
    fieldControlUnderlinedHeight: 42,
    buttonHeight: 27,
    datePickerMonthDaySize: 30,
    datePickerMonthBtnHeight: 18,
  },
  SMALL: {
    headbarHeight: 44,
    fontSize: 14,
    tableRowHeight: 43,
    tableHeaderHeight: 47,
    listIndentSize: 16,
    listItemPaddingX: 16,
    listItemPaddingY: 4,
    listItemTwoLinePaddingX: 16,
    listItemTwoLinePaddingY: 4,
    listItemThreeLinePaddingX: 16,
    listItemThreeLinePaddingY: 16,
    listItemMinHeight: 43,
    listItemOneLineMinHeight: 43,
    listItemTwoLineMinHeight: 43,
    listItemThreeLineMinHeight: 56,
    fieldControlUnderlinedHeight: 45,
    buttonHeight: 32,
    datePickerMonthDaySize: 40,
    datePickerMonthBtnHeight: 24,
  },
  NORMAL: {
    headbarHeight: 48,
    fontSize: 16,
    tableRowHeight: 48,
    tableHeaderHeight: 52,
    listIndentSize: 16,
    listItemPaddingX: 16,
    listItemPaddingY: 4,
    listItemTwoLinePaddingX: 16,
    listItemTwoLinePaddingY: 4,
    listItemThreeLinePaddingX: 16,
    listItemThreeLinePaddingY: 16,
    listItemMinHeight: 48,
    listItemOneLineMinHeight: 48,
    listItemTwoLineMinHeight: 48,
    listItemThreeLineMinHeight: 64,
    fieldControlUnderlinedHeight: 48,
    buttonHeight: 36,
    datePickerMonthDaySize: 40,
    datePickerMonthBtnHeight: 24,
  },
  LARGE: {
    headbarHeight: 56,
    fontSize: 18,
    tableRowHeight: 48,
    tableHeaderHeight: 52,
    listIndentSize: 16,
    listItemPaddingX: 16,
    listItemPaddingY: 4,
    listItemTwoLinePaddingX: 16,
    listItemTwoLinePaddingY: 4,
    listItemThreeLinePaddingX: 16,
    listItemThreeLinePaddingY: 16,
    listItemMinHeight: 48,
    listItemOneLineMinHeight: 48,
    listItemTwoLineMinHeight: 48,
    listItemThreeLineMinHeight: 64,
    fieldControlUnderlinedHeight: 48,
    buttonHeight: 36,
    datePickerMonthDaySize: 40,
    datePickerMonthBtnHeight: 24,
  },
  EXTRA_LARGE: {
    headbarHeight: 60,
    fontSize: 20,
    tableRowHeight: 48,
    tableHeaderHeight: 52,
    listIndentSize: 16,
    listItemPaddingX: 16,
    listItemPaddingY: 4,
    listItemTwoLinePaddingX: 16,
    listItemTwoLinePaddingY: 4,
    listItemThreeLinePaddingX: 16,
    listItemThreeLinePaddingY: 16,
    listItemMinHeight: 48,
    listItemOneLineMinHeight: 48,
    listItemTwoLineMinHeight: 48,
    listItemThreeLineMinHeight: 64,
    fieldControlUnderlinedHeight: 48,
    buttonHeight: 36,
    datePickerMonthDaySize: 40,
    datePickerMonthBtnHeight: 24,
  },
}
type FontConfig = {
  fontFamily: string
  importRule: () => void
}
export const THEME_FONTS: Record<ThemeFont, FontConfig> = {
  ROBOTO: {
    fontFamily: '\'Roboto\', sans-serif;',
    importRule: () => import('@fontsource/roboto/500.css'),
  },
  OPEN_SANS: {
    fontFamily: '\'Open Sans\', sans-serif;',
    importRule: () => import('@fontsource/open-sans/500.css'),
  },
  LATO: {
    fontFamily: '\'Lato\', sans-serif;',
    importRule: () => import('@fontsource/lato/400.css'),
  },
  MONTSERRAT: {
    fontFamily: '\'Montserrat\', sans-serif;',
    importRule: () => import('@fontsource/montserrat/500.css'),
  },
  OSWALD: {
    fontFamily: '\'Oswald\', sans-serif;',
    importRule: () => import('@fontsource/oswald/400.css'),
  },
  SOURCE_SANS_PRO: {
    fontFamily: '\'Source Sans Pro\', sans-serif;',
    importRule: () => import('@fontsource/source-sans-pro/600.css'),
  },
  SLABO_27PX: {
    fontFamily: '\'Slabo 27px\', sans-serif;',
    importRule: () => import('@fontsource/slabo-27px/400.css'),
  },
  RALEWAY: {
    fontFamily: '\'Raleway\', sans-serif;',
    importRule: () => import('@fontsource/raleway/500.css'),
  },
  PT_SANS: {
    fontFamily: '\'PT Sans\', sans-serif;',
    importRule: () => import('@fontsource/pt-sans/400.css'),
  },
  MERRIWEATHER: {
    fontFamily: '\'Merriweather\', sans-serif;',
    importRule: () => import('@fontsource/merriweather/400.css'),
  },
  NUNITO_SANS: {
    fontFamily: '\'Nunito Sans\', sans-serif;',
    importRule: () => import('@fontsource/nunito-sans/400.css'),
  },
  PROMPT: {
    fontFamily: '\'Prompt\', sans-serif;',
    importRule: () => import('@fontsource/prompt/400.css'),
  },
  WORK_SANS: {
    fontFamily: '\'Work Sans\', sans-serif;',
    importRule: () => import('@fontsource/work-sans/500.css'),
  },
}
