import mitt from 'mitt'
import type { ZodSchema, ZodTypeDef } from 'zod'
import type { Events, NotificationType, PromptCancellation, PromptConfirmation, PromptResponse } from '~/types/Reusable'
import type { SelectItemKey } from '~/types/VuetifyFix'

export default defineNuxtPlugin(() => {
  const emitter = mitt<Events>()
  function showNotification(type: NotificationType, title: string, timeout: number = 1500) {
    emitter.emit('show-notification', { type, timeout, title })
  }
  function hideNotification() {
    emitter.emit('hide-notification')
  }
  function createPromise<T>(id: string, cancellation: boolean = true) {
    const promise = new Promise<T>((resolve, reject) => {
      const handler = (event: PromptResponse<T>) => {
        if (event.id === id) {
          if (isPromptCancellation(event) && cancellation) {
            reject(event)
          } else if (isPromptConfirmation(event)) {
            resolve(event.value)
          }
          emitter.off('hide-prompt', handler)
        }
      }
      emitter.on('hide-prompt', handler)
    })
    return promise
  }
  function isPromptConfirmation<T>(event: PromptResponse<T>): event is PromptConfirmation<T> {
    return (<PromptConfirmation<T>> event).value !== undefined
  }
  function isPromptCancellation<T>(event: PromptResponse<T>): event is PromptCancellation<T> {
    return (<PromptCancellation<T>> event).cancelled !== undefined
  }
  function showInputPrompt<T extends string | number>(title: string, label: string, withCancellation: boolean = false, rules?: ZodSchema<T, ZodTypeDef, unknown>, initialValue?: T) {
    const id = crypto.randomUUID()
    const promise = createPromise<T>(id, withCancellation)
    emitter.emit('show-prompt', { type: 'text', id, label, title, rules, initialValue })
    return promise
  }
  function showSelectPrompt<T extends object | number | string, K extends SelectItemKey<T>>(title: string, label: string, items: T[], itemTitle: K, rules?: ZodSchema<T, ZodTypeDef, unknown>, initialValue?: T) {
    const id = crypto.randomUUID()
    const promise = createPromise<T>(id)
    emitter.emit('show-prompt', { type: 'select', id, label, title, items, itemTitle, rules, initialValue })
    return promise
  }
  function showConfirmationPrompt(title: string, question: string, cancellation?: boolean) {
    const id = crypto.randomUUID()
    const promise = createPromise<boolean>(id, cancellation)
    emitter.emit('show-prompt', { type: 'confirmation', id, question, title })
    return promise
  }
  function showSimpleTextPrompt(title: string, question: string) {
    const id = crypto.randomUUID()
    const promise = createPromise<boolean>(id, false)
    emitter.emit('show-prompt', { type: 'simple', id, question, title, confirmText: 'OK' })
    return promise
  }
  return {
    provide: {
      eventBus: emitter,
      notifications: {
        error: (title: string) => showNotification('error', title),
        success: (title: string) => showNotification('success', title),
        warning: (title: string) => showNotification('warning', title),
        info: (title: string, timeout?: number) => showNotification('info', title, timeout),
        hide: hideNotification,
      },
      prompts: {
        input: showInputPrompt,
        select: showSelectPrompt,
        confirm: showConfirmationPrompt,
        simpleText: showSimpleTextPrompt,
      },
    },
  }
})
