import { StorageSerializers, useAsyncState, useLocalStorage, useWebSocket, whenever } from '@vueuse/core'
import { defineStore } from 'pinia'
import { filter, Subject } from 'rxjs'
import type { LoginResponse } from '~/types/Login'
import { AVAILABLE_GRANTS, GrantPriority, type ApiToken, type ApiTokenMetadata, type DomainGrantedAuthority, type Grant, type GrantedAuthority, type Grants, type GrantType, type LoginAsApiToken, type OptilinkGrantedAuthority, type Permissions } from '~/types/Security'
import type { WebSocketEvent } from '~/types/WebSocket'

export const useSecurityStore = defineStore('Security', () => {
  const grants = computed<Grants>(() => {
    const values = AVAILABLE_GRANTS.reduce((a, c) => {
      a[c] = 'DENY'
      return a
    }, {} as Grants)
    if (currentUser.value) {
      currentUser.value.authorities.filter(isOptilinkGrantedAuthority).reduce((p, c) => {
        const authority = c.authority.replace('GRANT_', '') as Grant
        p[authority] = c.type
        return p
      }, values)
    }
    return values
  })
  const permissions = computed<Permissions>(() => {
    return AVAILABLE_GRANTS.reduce((a, c) => {
      a[c] = {
        DENY: () => hasAccess(c, 'DENY'),
        READ: () => hasAccess(c, 'READ'),
        WRITE: () => hasAccess(c, 'WRITE'),
      }
      return a
    }, {} as Permissions)
  })
  const domains = computed(() => {
    if (!currentUser.value) {
      return []
    }
    return currentUser.value.authorities
      .filter(isDomainGrantedAuthority)
      .map(c => c.domain)
  })
  const roles = computed(() => {
    if (!currentUser.value) {
      return []
    }
    return currentUser.value.authorities
      .filter(a => !isDomainGrantedAuthority(a) && !isOptilinkGrantedAuthority(a))
      .map(a => a.authority)
  })
  const username = computed(() => currentUserMetadata.value?.username)
  const isLoggedIn = computed(() => currentUser.value !== null)
  const isLoggedInAs = computed(() => currentUser.value && (currentUser.value as LoginAsApiToken).source !== undefined)
  const apiToken = computed(() => currentUser.value?.id)
  const currentUser = useLocalStorage<ApiToken>('web-current-user-v3', null, { serializer: StorageSerializers.object })
  const currentUserMetadata = useLocalStorage<ApiTokenMetadata | null>('web-current-user-meta-v3', null, { serializer: StorageSerializers.object })
  const { execute: loadVapidKey, state: vapidKey } = useAsyncState(() => findVapidKey().then(r => r.data), null, { immediate: false, shallow: true })
  const vapidSubscription = computed({
    get() {
      return currentUserMetadata.value?.vapidSubscription || null
    },
    set(value) {
      currentUserMetadata.value!.vapidSubscription = value
    },
  })
  const subject = new Subject<WebSocketEvent>()
  const deviceStatusEventStream = shallowRef(subject.pipe(filter(e =>
    e.type === 'DeviceStatus'
    || e.type === 'DepartmentStatus'
    || e.type === 'MappingStatus'
  )))
  const deviceAlarmEventStream = shallowRef(subject.pipe(filter(e =>
    e.type === 'DeviceAlarmEntry'
    || e.type === 'BusinessGroupAlarmEntry'
  )))

  const webSocketSessionId = ref<string>()
  const webSocketEndpoint = computed(() => {
    if (apiToken.value) {
      const urlPartsRegex = /(.*):\/\/(.*)/g
      const matcher = urlPartsRegex.exec(API_URL)
      let protocol = 'ws://'
      if (matcher) {
        if (matcher[1] === 'https') {
          protocol = 'wss://'
        }
        return `${protocol}${matcher[2]}/client-connect?Authorization=${apiToken.value}`
      }
    }
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { send, open, close, data, status } = useWebSocket<string>(webSocketEndpoint, {
    autoClose: false,
    heartbeat: {
      message: 'heartbeat',
      interval: 60 * 1000,
      pongTimeout: 60 * 1000,
    },
    autoReconnect: {
      retries: 5,
      delay: 60 * 1000,
      onFailed: () => {
        console.log('Failed to open websocket, realtime updates will not be received')
      },
    },
    immediate: false,
  })
  whenever(isLoggedIn, () => {
    if (vapidKey.value === null) {
      loadVapidKey()
    }
  }, { immediate: true })
  watch(data, (received) => {
    if (received) {
      const parsed: WebSocketEvent = JSON.parse(received)
      switch (parsed.type) {
        case 'SessionId': {
          webSocketSessionId.value = parsed.payload.id
          break
        }
        default: {
          subject.next(parsed)
          break
        }
      }
    }
  })
  watch(apiToken, (n) => {
    if (n && status.value === 'CLOSED') {
      open()
    } else if (!n && status.value === 'OPEN') {
      close()
    }
  }, { immediate: true })

  const load = (data: LoginResponse) => {
    currentUser.value = data.token
    currentUserMetadata.value = data.metadata
  }
  const unload = () => {
    currentUser.value = null
    currentUserMetadata.value = null
  }
  const hasAccess = (grant: Grant, requiredType: GrantType) => {
    let result = false
    const type = grants.value[grant]
    if (!type) {
      result = false
    } else if (GrantPriority[type] < GrantPriority[requiredType]) {
      result = false
    } else {
      result = true
    }
    return result
  }
  return {
    apiToken,
    isLoggedInAs,
    isLoggedIn,
    roles,
    domains,
    username,
    permissions,
    vapidKey,
    vapidSubscription,
    deviceStatusEventStream,
    deviceAlarmEventStream,
    load, // TODO - maybe remove them(loading it's happening based on user token from security store)
    unload,
  }
})

function isDomainGrantedAuthority(a: GrantedAuthority): a is DomainGrantedAuthority {
  return (<DomainGrantedAuthority>a).domain !== undefined
}
function isOptilinkGrantedAuthority(a: GrantedAuthority): a is OptilinkGrantedAuthority {
  return (<OptilinkGrantedAuthority>a).type !== undefined
}
