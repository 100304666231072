import type { InjectionKey } from 'vue'
import * as zod from 'zod'
import { DateTime } from 'luxon'
import type { DashboardSortingSettings } from './AccountSettings'
import { DeviceBufferSchema, type AbstractDepartmentPageLine, type DataStatus, type DepartmentPageLine, type DeviceBuffer } from './Device'
import type { TypedCallback } from './Reusable'
import { SortDirection, type AtLeast } from './Utils'

const DASHBOARD_TYPE = zod.literal('Dashboard')
const DASHBOARD_GROUP_TYPE = zod.literal('DashboardsGroupData')
const DashboardElementTypeSchema = zod.union([DASHBOARD_TYPE, DASHBOARD_GROUP_TYPE])

export const WidgetElementEditKey = Symbol() as InjectionKey<TypedCallback<WidgetElementDialogData>>

export const AvailableWidgetEvents = ['fullscreen', 'edit', 'delete', 'edit-annotations', 'edit-time', 'download'] as const
type WidgetEventTuple = typeof AvailableWidgetEvents
export type WidgetEvent = WidgetEventTuple[number]
export type WidgetDownloadType = 'PDF' | 'CSV' | 'EXCEL'

const MULTIGRID_CHART = zod.literal('MULTIGRID_CHART')
const LINE_CHART = zod.literal('LINE_CHART')
const MULTIPLE_Y_CHART = zod.literal('MULTIPLE_Y_CHART')
const PAGE = zod.literal('PAGE')
const LINE = zod.literal('LINE')
const TABLE = zod.literal('TABLE')
const BAR_CHART = zod.literal('BAR_CHART')
const PICTOGRAPH = zod.literal('PICTOGRAPH')
const CARD_CHART = zod.literal('CARD_CHART')
const COLLAPSABLE_TABLE = zod.literal('COLLAPSABLE_TABLE')
const TIME_SELECTION = zod.literal('TIME_SELECTION')
const WidgetType = zod.union([
  MULTIGRID_CHART,
  LINE_CHART,
  MULTIPLE_Y_CHART,
  PAGE,
  LINE,
  TABLE,
  BAR_CHART,
  PICTOGRAPH,
  CARD_CHART,
  COLLAPSABLE_TABLE,
  TIME_SELECTION,
])
export type WidgetType = zod.infer<typeof WidgetType>

const CompareWith = zod.union([
  zod.literal('AVERAGE_VALUE'),
  zod.literal('MIN_VALUE'),
  zod.literal('MAX_VALUE'),
  zod.literal('ANOTHER_BUFFER'),
  zod.literal('CUSTOM_VALUE'),
  zod.literal('NONE'),
])
export type CompareWith = zod.infer<typeof CompareWith>

const CompareType = zod.union([
  zod.literal('NONE'),
  zod.literal('NUMERIC'),
  zod.literal('PERCENTAGE'),
])
export type CompareType = zod.infer<typeof CompareType>

const TooltipCompareType = CompareType.or(zod.literal('NONE'))
export type TooltipCompareType = zod.infer<typeof TooltipCompareType>

const WidgetSerieType = zod.union([
  zod.literal('line'),
  zod.literal('bar'),
])
export type WidgetSerieType = zod.infer<typeof WidgetSerieType>
export const WIDGET_SERIE_TYPE: WidgetSerieType[] = ['line', 'bar']

const WidgetDataPresentation = zod.union([
  zod.literal('ROWS'),
  zod.literal('COLUMNS'),
])
export type WidgetDataPresentation = zod.infer<typeof WidgetDataPresentation>

export const WidgetIntervalTypeSchema = zod.union([
  zod.literal('LAST_24_HOURS'),
  zod.literal('LAST_7_DAYS'),
  zod.literal('LAST_30_DAYS'),
  zod.literal('THIS_MONTH'),
  zod.literal('LAST_MONTH'),
  zod.literal('INTERVAL'),
])
export type WidgetIntervalType = zod.infer<typeof WidgetIntervalTypeSchema>

const CollapseOn = zod.union([
  zod.literal('ON_DAY'),
  zod.literal('ON_HOUR'),
  zod.literal('NONE'),
])
export type CollapseOn = zod.infer<typeof CollapseOn>

const WidgetElementDataFilter = zod.union([
  zod.literal('SHOW_ALL'),
  zod.literal('SHOW_ONLY_VALUES'),
  zod.literal('SHOW_VALUES_AND_UNITS'),
])
export type WidgetElementDataFilter = zod.infer<typeof WidgetElementDataFilter>

const WidgetPosition = zod.object({
  i: zod.string(),
  x: zod.number(),
  y: zod.number(),
  w: zod.number(),
  h: zod.number(),
})
export type WidgetPosition = zod.infer<typeof WidgetPosition>

const BaseWidgetInterval = zod.object({
  start: zod.string().superRefine(DateTimeValidator),
  end: zod.string().superRefine(DateTimeValidator),
  type: WidgetIntervalTypeSchema.default('LAST_24_HOURS'),
})
export const WidgetIntervalSchema = BaseWidgetInterval.extend({
  compareTo: BaseWidgetInterval.nullable().default(null),
}).superRefine((input, ctx) => {
  if (DateTime.fromISO(input.start) > DateTime.fromISO(input.end)) {
    ctx.addIssue({
      code: 'custom',
      message: '$t(Validation.startDateAfterEndDate)',
      path: ['start'],
    })
  }
})
export type BaseWidgetInterval = zod.infer<typeof BaseWidgetInterval>
export type WidgetInterval = zod.infer<typeof WidgetIntervalSchema>

export const WidgetMarkAreaElementSchema = zod.object({
  label: zod.string().min(1),
  startDate: zod.string().refine(input => DateTime.fromISO(input).isValid, '$t(Validation.invalid_date_format)'),
  endDate: zod.string().refine(input => DateTime.fromISO(input).isValid, '$t(Validation.invalid_date_format)'),
  color: zod.string().default('#FF0000FF'),
}).superRefine((input, ctx) => {
  if (DateTime.fromISO(input.startDate) > DateTime.fromISO(input.endDate)) {
    ctx.addIssue({
      code: 'custom',
      message: '$t(Validation.startDateAfterEndDate)',
      path: ['startDate'],
    })
  }
})

export type WidgetMarkAreaElement = zod.infer<typeof WidgetMarkAreaElementSchema>

const WidgetElementBufferSupportSchema = zod.object({
  pcNumber: zod.number().nullable(),
  useDefaultPcNumber: zod.boolean().default(true),
  buffer: DeviceBufferSchema.nullable(),
})
type FullWidgetElementBufferSupport = zod.infer<typeof WidgetElementBufferSupportSchema>
export type WidgetElementBufferSupport = AtLeast<FullWidgetElementBufferSupport, 'pcNumber' | 'useDefaultPcNumber'>

const WidgetElementSchema = zod.object({
  id: zod.string(),
  order: zod.number(),
  name: zod.string().min(1).nullable(),
  hexacode: zod.string(),
  department: zod.number(),
  subDepartment: zod.number(),
  pageNumber: zod.number(),
  lineNumber: zod.number(),
  type: WidgetType,
})
export type WidgetElement = zod.infer<typeof WidgetElementSchema>

export const BarChartWidgetElementSchema = WidgetElementSchema.extend({
  type: BAR_CHART,
  color: zod.string().nullable(),
})
export type BarChartWidgetElement = zod.infer<typeof BarChartWidgetElementSchema>

export const CardChartWidgetElementCompareToBaseSchema = WidgetElementSchema.extend({
  type: CARD_CHART,
  color: zod.string().nullable(),
  serieType: WidgetSerieType,
})
const CardChartWidgetElementBase = CardChartWidgetElementCompareToBaseSchema.extend({
  showAxis: zod.boolean(),
  showSparkline: zod.boolean(),
  compareType: CompareType,
  compareWith: CompareWith,
})
const CardChartWidgetElementWithCompareToValue = CardChartWidgetElementBase.extend({
  compareWith: zod.literal('CUSTOM_VALUE'),
  compareToValue: zod.string().or(zod.number()),
})
const CardChartWidgetElementWithCompareToAnotherBuffer = CardChartWidgetElementBase.extend({
  compareWith: zod.literal('ANOTHER_BUFFER'),
  compareTo: CardChartWidgetElementCompareToBaseSchema,
})
const CardChartWidgetElementWithCompareToAverageValue = CardChartWidgetElementBase.extend({
  compareWith: zod.literal('AVERAGE_VALUE'),
})
const CardChartWidgetElementWithCompareToMinValue = CardChartWidgetElementBase.extend({
  compareWith: zod.literal('MIN_VALUE'),
})
const CardChartWidgetElementWithCompareToMaxValue = CardChartWidgetElementBase.extend({
  compareWith: zod.literal('MAX_VALUE'),
})
const CardChartWidgetElementWithCompareToNone = CardChartWidgetElementBase.extend({
  compareWith: zod.literal('NONE'),
})
const CardChartWidgetElementUnion = zod.discriminatedUnion('compareWith', [
  CardChartWidgetElementWithCompareToValue,
  CardChartWidgetElementWithCompareToAnotherBuffer,
  CardChartWidgetElementWithCompareToAverageValue,
  CardChartWidgetElementWithCompareToMinValue,
  CardChartWidgetElementWithCompareToMaxValue,
  CardChartWidgetElementWithCompareToNone,
])

export const CardChartWidgetElementSchema = CardChartWidgetElementCompareToBaseSchema.extend({
  type: CARD_CHART,
  showAxis: zod.boolean().nullish(),
  showSparkline: zod.boolean().nullish(),
  compareType: CompareType.nullish(),
  compareWith: CompareWith.nullish(),
  compareToValue: zod.number().or(zod.string()).nullish(),
  compareTo: CardChartWidgetElementCompareToBaseSchema.nullish(),
})
export type CardChartWidgetElementBase = zod.infer<typeof CardChartWidgetElementCompareToBaseSchema>
export type CardChartWidgetElement = zod.infer<typeof CardChartWidgetElementSchema>

const CollapsableTableWidgetElement = WidgetElementSchema.extend({
  type: COLLAPSABLE_TABLE,
  index: zod.number(),
  visible: zod.boolean(),
})
export type CollapsableTableWidgetElement = zod.infer<typeof CollapsableTableWidgetElement>

export const LineChartWidgetElementSchema = WidgetElementSchema.merge(WidgetElementBufferSupportSchema).extend({
  type: LINE_CHART,
  color: zod.string().nullable(),
  showAverageValue: zod.boolean(),
  showLowestValue: zod.boolean(),
  showHighestValue: zod.boolean(),
  serieType: WidgetSerieType,
  compareTypeTooltip: TooltipCompareType,
})
export type LineChartWidgetElement = zod.infer<typeof LineChartWidgetElementSchema>

export const LineWidgetElementSchema = WidgetElementSchema.extend({
  type: LINE,
})
export type LineWidgetElement = zod.infer<typeof LineWidgetElementSchema>

const PageWidgetElement = WidgetElementSchema.extend({
  type: PAGE,
}).omit({ lineNumber: true })
export type PageWidgetElement = zod.infer<typeof PageWidgetElement>

export const MultigridChartWidgetElementSchema = WidgetElementSchema.merge(WidgetElementBufferSupportSchema).extend({
  type: MULTIGRID_CHART,
  color: zod.string().nullable(),
  showAverageValue: zod.boolean(),
  showLowestValue: zod.boolean(),
  showHighestValue: zod.boolean(),
  serieType: WidgetSerieType,
  compareTypeTooltip: TooltipCompareType,
})
export type MultigridChartWidgetElement = zod.infer<typeof MultigridChartWidgetElementSchema>

export const MultipleYChartWidgetElementSchema = WidgetElementSchema.merge(WidgetElementBufferSupportSchema).extend({
  type: MULTIPLE_Y_CHART,
  color: zod.string().nullable(),
  showAverageValue: zod.boolean(),
  showLowestValue: zod.boolean(),
  showHighestValue: zod.boolean(),
  compareTypeTooltip: TooltipCompareType,
  yAxisNumber: zod.number(),
})
export type MultipleYChartWidgetElement = zod.infer<typeof MultipleYChartWidgetElementSchema>

export const PictographWidgetElementSchema = WidgetElementSchema.extend({
  type: PICTOGRAPH,
  symbol: zod.string().nullable(),
  color: zod.string().nullable(),
  backgroundColor: zod.string().nullable(),
  group: zod.number(),
})
export type PictographWidgetElement = zod.infer<typeof PictographWidgetElementSchema>

export const TableWidgetElementSchema = WidgetElementSchema.extend({
  type: TABLE,
  lineNumber: zod.number().nullable(),
  yAxisNumber: zod.number(),
  label: zod.string().min(1),
  dataFilter: WidgetElementDataFilter,
})
export type TableWidgetElement = zod.infer<typeof TableWidgetElementSchema>

export const TimeSelectionWidgetElementSchema = WidgetElementSchema.extend({
  type: TIME_SELECTION,
})
export type TimeSelectionWidgetElement = zod.infer<typeof TimeSelectionWidgetElementSchema>

export const WidgetElementUnionSchema = zod.discriminatedUnion('type', [
  PageWidgetElement, LineWidgetElementSchema, PictographWidgetElementSchema,
  BarChartWidgetElementSchema, TableWidgetElementSchema, CardChartWidgetElementSchema,
  MultipleYChartWidgetElementSchema, LineChartWidgetElementSchema, MultigridChartWidgetElementSchema,
  CollapsableTableWidgetElement, TimeSelectionWidgetElementSchema,
]).superRefine((input, ctx) => {
  if (input.type === 'CARD_CHART') {
    const results = CardChartWidgetElementUnion.safeParse(input)
    if (results.error) {
      results.error.issues.forEach(ctx.addIssue)
      return zod.NEVER
    }
  }
})
export type WidgetElementUnion = zod.infer<typeof WidgetElementUnionSchema>

export const MarkableWidgetSchema = zod.object({
  markAreaElements: WidgetMarkAreaElementSchema.array(),
})
export type MarkableWidget = zod.infer<typeof MarkableWidgetSchema>

const IntervalSupport = zod.object({
  interval: WidgetIntervalSchema,
})
const IntervalLockSupportSchema = zod.object({
  dashboardIntervalEnabled: zod.boolean().default(false),
})
export type IntervalLockSupport = zod.infer<typeof IntervalLockSupportSchema>
export type IntervalSupport = zod.infer<typeof IntervalSupport>

const Widget = zod.object({
  id: zod.string(),
  name: zod.string().trim().min(1),
  type: WidgetType,
  position: WidgetPosition,
  elements: WidgetElementSchema.array(),
})
export type Widget = zod.infer<typeof Widget>

const PageWidgetLineSettingsSchema = zod.object({
  lineNumber: zod.number(),
  visible: zod.boolean().default(true),
})

const PageWidget = Widget.extend({
  type: PAGE,
  elements: PageWidgetElement.array(),
  linesSettings: PageWidgetLineSettingsSchema.array(),
})
const PageWidgetPartial = PageWidget.deepPartial().extend({ type: PAGE })
export type PageWidgetLineSettings = zod.infer<typeof PageWidgetLineSettingsSchema>
export type PageWidget = zod.infer<typeof PageWidget>

const LineWidget = Widget.extend({
  type: LINE,
  elements: LineWidgetElementSchema.array(),
})
const LineWidgetPartial = LineWidget.deepPartial().extend({ type: LINE })
export type LineWidget = zod.infer<typeof LineWidget>

const PictographWidget = Widget.extend({
  type: PICTOGRAPH,
  defaultSymbol: zod.string(),
  defaultColor: zod.string(),
  defaultBackgroundColor: zod.string(),
  min: zod.number(),
  max: zod.number(),
  showAxis: zod.boolean(),
  showAverageValue: zod.boolean(),
  showLowestValue: zod.boolean(),
  showHighestValue: zod.boolean(),
  showCurrentValue: zod.boolean(),
  enableLineEdit: zod.boolean(),
  elements: PictographWidgetElementSchema.array(),
})
const PictographWidgetPartial = PictographWidget.deepPartial().extend({ type: PICTOGRAPH })
export type PictographWidget = zod.infer<typeof PictographWidget>

const BarChartWidget = Widget.extend({
  type: BAR_CHART,
  sortDirection: SortDirection,
  dataPresentation: WidgetDataPresentation,
  elements: BarChartWidgetElementSchema.array(),
})
const BarChartWidgetPartial = BarChartWidget.deepPartial().extend({ type: BAR_CHART })
export type BarChartWidget = zod.infer<typeof BarChartWidget>

const CardChartWidget = Widget.merge(IntervalSupport).merge(IntervalLockSupportSchema).extend({
  type: CARD_CHART,
  elements: CardChartWidgetElementSchema.array().max(1),
})
const CardChartWidgetPartial = CardChartWidget.deepPartial().extend({ type: CARD_CHART })
export type CardChartWidget = zod.infer<typeof CardChartWidget>

const TableWidgetSettings = zod.object({
  visible: zod.boolean(),
  identifiers: zod.string().array(),
})
const RowsTableWidgetSettings = TableWidgetSettings.extend({})
const ColumnsTableWidgetSettings = TableWidgetSettings.extend({
  currentIndex: zod.number(),
  sortOrder: zod.number().nullable(),
  sortDesc: zod.boolean().nullable(),
})
const TableWidget = Widget.extend({
  type: TABLE,
  dataPresentation: WidgetDataPresentation,
  fixedTitlesAndDescriptions: zod.boolean(),
  rowsSettings: RowsTableWidgetSettings.array(),
  columnsSettings: ColumnsTableWidgetSettings.array(),
  elements: TableWidgetElementSchema.array(),
})
const TableWidgetPartial = TableWidget.deepPartial().extend({ type: TABLE })
export type RowsTableWidgetSettings = zod.infer<typeof RowsTableWidgetSettings>
export type ColumnsTableWidgetSettings = zod.infer<typeof ColumnsTableWidgetSettings>
export type TableWidget = zod.infer<typeof TableWidget>

const MultipleYChartWidget = Widget.merge(MarkableWidgetSchema).merge(IntervalSupport).merge(IntervalLockSupportSchema).extend({
  type: MULTIPLE_Y_CHART,
  elements: MultipleYChartWidgetElementSchema.array(),
})
const MultipleYChartWidgetPartial = MultipleYChartWidget.deepPartial().extend({ type: MULTIPLE_Y_CHART })
export type MultipleYChartWidget = zod.infer<typeof MultipleYChartWidget>

const LineChartWidget = Widget.merge(MarkableWidgetSchema).merge(IntervalSupport).merge(IntervalLockSupportSchema).extend({
  type: LINE_CHART,
  elements: LineChartWidgetElementSchema.array(),
})
const LineChartWidgetPartial = LineChartWidget.deepPartial().extend({ type: LINE_CHART })
export type LineChartWidget = zod.infer<typeof LineChartWidget>

const MultigridChartWidget = Widget.merge(MarkableWidgetSchema).merge(IntervalSupport).merge(IntervalLockSupportSchema).extend({
  type: MULTIGRID_CHART,
  elements: MultigridChartWidgetElementSchema.array(),
})
const MultigridChartWidgetPartial = MultigridChartWidget.deepPartial().extend({ type: MULTIGRID_CHART })
export type MultigridChartWidget = zod.infer<typeof MultigridChartWidget>

const CollapsableTableWidget = Widget.merge(IntervalSupport).merge(IntervalLockSupportSchema).extend({
  type: COLLAPSABLE_TABLE,
  collapseOn: CollapseOn,
  sortField: zod.string(),
  sortDesc: zod.boolean(),
  pageLength: zod.number(),
  showTimestamps: zod.boolean(),
  timestampsIndex: zod.number(),
  showUnits: zod.boolean(),
  elements: CollapsableTableWidgetElement.array(),
})
const CollapsableTableWidgetPartial = CollapsableTableWidget.deepPartial().extend({ type: COLLAPSABLE_TABLE })
export type CollapsableTableWidget = zod.infer<typeof CollapsableTableWidget>

const TimeSelectionWidgetSchema = Widget.merge(IntervalSupport).extend({
  type: TIME_SELECTION,
  elements: TimeSelectionWidgetElementSchema.array().max(0),
})
const TimeSelectionWidgetInputSchema = TimeSelectionWidgetSchema.deepPartial()
export type TimeSelectionWidget = zod.infer<typeof TimeSelectionWidgetSchema>

export const WidgetSchema = zod.discriminatedUnion('type', [
  PageWidget, LineWidget, PictographWidget, BarChartWidget,
  CardChartWidget, TableWidget, MultipleYChartWidget, LineChartWidget,
  MultigridChartWidget, CollapsableTableWidget, TimeSelectionWidgetSchema,
])

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WidgetSchemaInput = zod.discriminatedUnion('type', [
  PageWidgetPartial, LineWidgetPartial, PictographWidgetPartial, BarChartWidgetPartial,
  CardChartWidgetPartial, TableWidgetPartial, MultipleYChartWidgetPartial, LineChartWidgetPartial,
  MultigridChartWidgetPartial, CollapsableTableWidgetPartial, TimeSelectionWidgetInputSchema,
])
export type Widgets = zod.infer<typeof WidgetSchema>
export type WidgetsInput = zod.infer<typeof WidgetSchemaInput>

const Breakpoint = zod.union([
  zod.literal('xl'),
  zod.literal('lg'),
  zod.literal('md'),
  zod.literal('sm'),
  zod.literal('xs'),
])
export type Breakpoint = zod.infer<typeof Breakpoint>

const DashboardElement = zod.object({
  id: zod.string().optional(),
  order: zod.number(),
  name: zod.string().trim().min(1),
  type: DashboardElementTypeSchema,
})

export const DashboardSchema = DashboardElement.extend({
  accountId: zod.string().optional(),
  breakpoint: Breakpoint,
  favorite: zod.boolean(),
  shared: zod.boolean().optional(),
  groupId: zod.string().nullable(),
  widgets: WidgetSchema.array(),
  type: DASHBOARD_TYPE,
})
export type Dashboard = zod.infer<typeof DashboardSchema>

export type LinkedDashboardToken = {
  id: string
  account: string
  dashboardShareToken: string
}

export type LinkedDashboard = Dashboard & {
  token: LinkedDashboardToken
}

export const DashboardGroupSchema = DashboardElement.extend({
  type: DASHBOARD_GROUP_TYPE,
  dashboards: DashboardSchema.array().nonempty(),
})
export type DashboardGroup = zod.infer<typeof DashboardGroupSchema>

export const DashboardAndGroupsUnionSchema = zod.discriminatedUnion('type', [DashboardSchema, DashboardGroupSchema])
export type DashboardAndGroupsUnion = zod.infer<typeof DashboardAndGroupsUnionSchema>

export interface LinkedDashboardsEntry {
  id: string
  elements: LinkedDashboard[]
  type: 'LINKED_DASHBOARDS'
}
export interface DashboardsEntry {
  id: string
  elements: DashboardAndGroupsUnion[]
  type: 'DASHBOARDS'
}

export type DashboardsGroupData = {
  group: {
    id?: string
    name: string
    order: number
  }
  linkedDashboards: string[]
}
export type DashboardsSortAndGroupData = {
  sortSettings: DashboardSortingSettings
  dashboards: Record<string, number>
  added: DashboardsGroupData[]
  removed: DashboardsGroupData[]
  updated: DashboardsGroupData[]
}

// widget data
export interface WidgetBufferValue {
  value: number | null
  timestamp: string
}
export interface WidgetElementData extends AbstractDepartmentPageLine {
  id: string
  order: number
  data: number | null
}
export interface GraphWidgetElementData extends WidgetElementData {
  color: string
}

export type BarChartWidgetElementData = GraphWidgetElementData
export interface CardChartWidgetElementData extends GraphWidgetElementData {
  showAxis: boolean
  showSparkline: boolean
  compareWith: CompareWith
  compareType: CompareType
  serieType: WidgetSerieType
  compareToValue: number | null
  compareTo: CardChartWidgetElementData | null
  values: WidgetBufferValue[]
  availableUnits: Record<string, string>

}
export interface CollapsableTableWidgetElementData extends WidgetElementData {
  index: number
  visible: boolean
}
export interface LineChartWidgetElementData extends GraphWidgetElementData {
  showAverageValue: boolean
  showLowestValue: boolean
  showHighestValue: boolean
  serieType: WidgetSerieType
  compareTypeTooltip: TooltipCompareType
  values: WidgetBufferValue[]
  compareToValues: WidgetBufferValue[]
  availableUnits: Record<string, string>
}
export interface LineWidgetElementData extends WidgetElementData {
  readOnly: boolean
}
export interface MultigridChartWidgetElementData extends GraphWidgetElementData {
  showAverageValue: boolean
  showLowestValue: boolean
  showHighestValue: boolean
  serieType: WidgetSerieType
  compareTypeTooltip: TooltipCompareType
  values: WidgetBufferValue[]
  compareToValues: WidgetBufferValue[]
  availableUnits: Record<string, string>
}
export interface MultipleYChartWidgetElementData extends GraphWidgetElementData {
  showAverageValue: boolean
  showLowestValue: boolean
  showHighestValue: boolean
  yaxisNumber: number
  compareTypeTooltip: TooltipCompareType
  values: WidgetBufferValue[]
  compareToValues: WidgetBufferValue[]
  availableUnits: Record<string, string>
}
export interface PageWidgetElementData extends WidgetElementData {
  readOnly: boolean
}
export interface PictographWidgetElementData extends GraphWidgetElementData {
  symbol: string
  backgroundColor: string
  group: string
  readOnly: boolean
}
export interface TableWidgetElementData extends WidgetElementData {
  dataFilter: WidgetElementDataFilter
  yaxisNumber: number
  identifiers: string[]
  label: string
  readOnly: boolean
}
export interface AbstractWidgetData<_T extends WidgetElementData = WidgetElementData> {
  type: WidgetType
}
export interface BarChartWidgetData extends AbstractWidgetData<BarChartWidgetElementData> {
  type: 'BAR_CHART'
  sortDirection: SortDirection
  dataPresentation: WidgetDataPresentation
  elements: BarChartWidgetElementData[]
}
export interface CardChartWidgetData extends AbstractWidgetData<CardChartWidgetElementData>, IntervalSupport {
  type: 'CARD_CHART'
  elements: CardChartWidgetElementData[]
  timestamps: string[]
}
export interface LineChartWidgetData extends AbstractWidgetData<LineChartWidgetElementData>, MarkableWidget, IntervalSupport {
  type: 'LINE_CHART'
  elements: LineChartWidgetElementData[]
  timestamps: string[]
  compareToTimestamps: string[]
}
export interface LineWidgetData extends AbstractWidgetData<LineWidgetElementData> {
  type: 'LINE'
  elements: LineWidgetElementData[]
}
export interface MultigridChartWidgetData extends AbstractWidgetData<MultigridChartWidgetElementData>, MarkableWidget, IntervalSupport {
  type: 'MULTIGRID_CHART'
  elements: MultigridChartWidgetElementData[]
  timestamps: string[]
  compareToTimestamps: string[]
}
export interface MultipleYChartWidgetData extends AbstractWidgetData<MultipleYChartWidgetElementData>, MarkableWidget, IntervalSupport {
  type: 'MULTIPLE_Y_CHART'
  elements: MultipleYChartWidgetElementData[]
  timestamps: string[]
  compareToTimestamps: string[]
}
export interface PageWidgetData extends AbstractWidgetData<PageWidgetElementData> {
  type: 'PAGE'
  elements: PageWidgetElementData[]
}
export interface PictographWidgetData extends AbstractWidgetData<PictographWidgetElementData> {
  type: 'PICTOGRAPH'
  defaultSymbol: string
  defaultColor: string
  defaultBackgroundColor: string
  min: number
  max: number
  showAxis: boolean
  showAverageValue: boolean
  showLowestValue: boolean
  showHighestValue: boolean
  showCurrentValue: boolean
  enableLineEdit: boolean
  elements: PictographWidgetElementData[]
}
export interface TableWidgetHeaderData {
  text: string
  value: string
  index: number
  number: number
  sortable: boolean
  status: DataStatus
  identifiers: string[]
}
export interface TableWidgetData extends AbstractWidgetData<TableWidgetElementData> {
  type: 'TABLE'
  elements: TableWidgetElementData[]
  errorStatuses: DataStatus[]
  headers: TableWidgetHeaderData[]
  data: Record<string, TableWidgetElementData>[]
}
export interface CollapsableTableHeader {
  text: string
  value: string
  index: number
  order: number
  visible: boolean
  status: DataStatus
}
export type CollapsableTableCellType = 'NUMERIC' | 'DATE'
export interface CollapsableTableCell {
  value: string
  unit: string
  type: CollapsableTableCellType
}
export interface NumericCollapsableTableCell extends CollapsableTableCell {
  text: number
  type: 'NUMERIC'
}
export interface StringCollapsableTableCell extends CollapsableTableCell {
  text: string
  type: 'DATE'
}
export type MappedCollapsableTableCell = NumericCollapsableTableCell | StringCollapsableTableCell
export type CollapsableTableRow = Record<string, MappedCollapsableTableCell> & {
  timestamp: StringCollapsableTableCell
}
export interface CollapsableTableWidgetData extends AbstractWidgetData<CollapsableTableWidgetElementData>, IntervalSupport {
  type: 'COLLAPSABLE_TABLE'
  elements: CollapsableTableWidgetElementData[]
  collapseOn: CollapseOn
  sortField: string
  sortDesc: boolean
  headers: CollapsableTableHeader[]
  rows: CollapsableTableRow[]
  pageLength: number
  showTimestamps: boolean
  timestampsIndex: number
  showUnits: boolean
}
export type WidgetsData = PageWidgetData | LineWidgetData | PictographWidgetData | BarChartWidgetData | CardChartWidgetData | TableWidgetData | MultipleYChartWidgetData | LineChartWidgetData |
  MultigridChartWidgetData | CollapsableTableWidgetData

export interface BaseWidgetElementDialogProps {
  type: WidgetType
  validationPath?: string
  // element: WidgetElement
}
export interface PageWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'PAGE'
}
export interface LineWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'LINE'
  line?: DepartmentPageLine
}
export interface PictographWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'PICTOGRAPH'
  line: DepartmentPageLine
}
export interface BarChartWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'BAR_CHART'
  line?: DepartmentPageLine
}
export interface CardChartWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'CARD_CHART'
  line: DepartmentPageLine
  buffer?: DeviceBuffer
  enabledAdvancedSettings?: boolean
  enableCompareWith?: boolean
  enableAxisSettings?: boolean
  enableColorPicker?: boolean
  enableSparkline?: boolean
  validationSchema?: zod.ZodSchema
}
export interface TableWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'TABLE'
  line: DepartmentPageLine
}
export interface MultipleYChartWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'MULTIPLE_Y_CHART'
  line?: DepartmentPageLine
  compareEnabled: boolean
  allowBufferCreation: boolean
}
export interface LineChartWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  compareEnabled: boolean
  line?: DepartmentPageLine
  allowBufferCreation: boolean
  type: 'LINE_CHART'
}
export interface MultigridChartWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  compareEnabled: boolean
  type: 'MULTIGRID_CHART'
  line?: DepartmentPageLine
  allowBufferCreation: boolean
}
export interface CollapsableTableWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'COLLAPSABLE_TABLE'
  line: DepartmentPageLine
}
export interface WimeSelectionWidgetElementDialogProps extends BaseWidgetElementDialogProps {
  type: 'TIME_SELECTION'
}
export type WidgetElementDialogProps = PageWidgetElementDialogProps | LineWidgetElementDialogProps | PictographWidgetElementDialogProps
  | BarChartWidgetElementDialogProps | CardChartWidgetElementDialogProps | TableWidgetElementDialogProps | MultipleYChartWidgetElementDialogProps
  | LineChartWidgetElementDialogProps | CollapsableTableWidgetElementDialogProps | MultigridChartWidgetElementDialogProps | WimeSelectionWidgetElementDialogProps
export type WidgetElementDialogData = {
  settings: WidgetElementDialogProps
  element: WidgetElementUnion
  callback: TypedCallback<WidgetElementUnion>
}

export function supportsBufferOperations(input: WidgetElementUnion): input is WidgetElementUnion & WidgetElementBufferSupport {
  return 'buffer' in input
}
export function allowsLineSelection(input: WidgetElementUnion): input is WidgetElementUnion & { lineNumber: number } {
  return 'lineNumber' in input
}
export function hasIntervalSupport(input: Widgets): input is Widgets & IntervalSupport {
  return 'interval' in input
}
export function hasIntervalLockSupport(input: Widgets): input is Widgets & IntervalLockSupport {
  return 'dashboardIntervalEnabled' in input
}
export function hasMarkableAreaSupport(input: Widgets): input is Widgets & MarkableWidget {
  return 'markAreaElements' in input
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DashboardShareTokenSchema = zod.object({
  id: zod.string().nullable().default(null),
  dashboard: zod.string(),
  expiresAt: zod.string().refine(input => DateTime.fromISO(input).isValid, '$t(Validation.invalid_date_format)'),
  createdAt: zod.string().nullable(),
  isValid: zod.boolean().default(true),
})
export type DashboardShareToken = zod.infer<typeof DashboardShareTokenSchema>
