import { mdiBell, mdiCheck, mdiClose } from '@mdi/js'
import type { DataStatus, DeviceStatus } from '~/types/Device'
import type { DeviceStatusSettings } from '~/types/DeviceStatus'
import type { VeeValidationVuetifyConfig } from '~/types/VeeValidationVuetifyConfig'

export const Status: Record<DeviceStatus, DeviceStatusSettings> = {
  ON: {
    color: 'success',
    icon: mdiCheck,
    isOnline: true,
    onlineEquivalent: 'ON',
    offlineEquivalent: 'OFF',
    equivalent: 'OFF',
    alarmClearEquivalent: 'ON',
    alarmEquivalent: 'ON_ALARM',
  },
  ON_ALARM: {
    color: 'error',
    icon: mdiBell,
    isOnline: true,
    onlineEquivalent: 'ON_ALARM',
    offlineEquivalent: 'OFF_ALARM',
    equivalent: 'OFF_ALARM',
    alarmClearEquivalent: 'ON',
    alarmEquivalent: 'ON_ALARM',
  },
  OFF: {
    color: 'info',
    icon: mdiClose,
    isOnline: false,
    onlineEquivalent: 'ON',
    offlineEquivalent: 'OFF',
    equivalent: 'ON',
    alarmClearEquivalent: 'OFF',
    alarmEquivalent: 'OFF_ALARM',
  },
  OFF_ALARM: {
    color: 'info',
    icon: mdiBell,
    isOnline: false,
    onlineEquivalent: 'ON_ALARM',
    offlineEquivalent: 'OFF_ALARM',
    equivalent: 'ON_ALARM',
    alarmClearEquivalent: 'OFF',
    alarmEquivalent: 'OFF_ALARM',
  },
}

export const VUETIFY_INPUT_CONFIG = (state: VeeValidationVuetifyConfig) => {
  return {
    props: {
      'error-messages': state.errors,
      'base-color': state.valid ? 'success' : '',
      'color': state.valid ? 'success' : 'error',
    },
  }
}

export function translateDataStatus(status: DataStatus) {
  const { t } = useI18n()
  if (status === 'DEVICE_NOT_FOUND') {
    return t('DataStatus.DEVICE_NOT_FOUND')
  } else if (status === 'DEVICE_CAN_RECEIVED') {
    return t('DataStatus.DEVICE_CAN_RECEIVED')
  } else if (status === 'ADF_NOT_FOUND') {
    return t('DataStatus.ADF_NOT_FOUND')
  } else if (status === 'UNAUTHORIZED') {
    return t('DataStatus.UNAUTHORIZED')
  } else if (status === 'LINE_NOT_PRESENT') {
    return t('DataStatus.LINE_NOT_PRESENT')
  } else if (status === 'LINE_NOT_PRESENT_IN_ADF') {
    return t('DataStatus.LINE_NOT_PRESENT_IN_ADF')
  } else if (status === 'DEPARTMENT_NOT_PRESENT') {
    return t('DataStatus.DEPARTMENT_NOT_PRESENT')
  } else if (status === 'PAGE_NOT_PRESENT') {
    return t('DataStatus.PAGE_NOT_PRESENT')
  } else if (status === 'NO_DATA') {
    return t('DataStatus.NO_DATA')
  } else if (status === 'ERROR') {
    return t('DataStatus.ERROR')
  } else if (status === 'DEVICE_READ_ONLY') {
    return t('DataStatus.DEVICE_READ_ONLY')
  } else if (status === 'BUFFER_NOT_PRESENT') {
    return t('DataStatus.BUFFER_NOT_PRESENT')
  } else if (status === 'DEVICE_OFFLINE') {
    return t('DataStatus.DEVICE_OFFLINE')
  } else if (status === 'DEVICE_TIMEOUT') {
    return t('DataStatus.DEVICE_TIMEOUT')
  } else if (status === 'DATA_NOT_FOUND') {
    return t('DataStatus.DATA_NOT_FOUND')
  } else if (status === 'DEPARTMENT_OFFLINE') {
    return t('DataStatus.DEPARTMENT_OFFLINE')
  }
  return status
}
