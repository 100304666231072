import { timestamp, useAsyncState, whenever } from '@vueuse/core'
import { defineStore } from 'pinia'
import type { Subscription } from 'rxjs'
import { filter } from 'rxjs'
import type { DepartmentNameRequest, DepartmentPage, DepartmentPageLinesRequest, DepartmentPageRequest } from '~/types/Device'
import type { DeviceConfigurationMap, DeviceUnion, LoadableBusinessGroupDevices, LoadableDepartment, LoadableDevice, LoadableDeviceUnion } from '~/types/DeviceConfiguration'
import type { DepartmentStatusEvent, DeviceStatusEvent, MappingStatusEvent, WebSocketEvent } from '~/types/WebSocket'

const DEPARTMENT_NAMES_CACHE_DURATION = 1000 * 60 * 5
const DEPARTMENT_PAGES_CACHE_DURATION = 1000 * 60 * 5
export const DEPARTMENT_LINES_CACHE_DURATION = 1000 * 60 * 1

export const useDevicesStore = defineStore('Devices', () => {
  const settingsStore = useSettingsStore()
  const securityStore = useSecurityStore()
  const { deviceSortingSettings } = storeToRefs(settingsStore)
  const { isLoggedIn, deviceStatusEventStream } = storeToRefs(securityStore)
  const request = ref(Promise.resolve<DeviceUnion[]>([]))
  const controller = useAsyncState(() => findDevices().then(r => processDevices(r.data)), [], { immediate: false, shallow: false })
  const devices = controller.state
  const isLoading = controller.isLoading
  /**
   * Transforms the devices received from server to something we can work with, adding additional properties
   */
  const processDevices = (devices: DeviceUnion[]): LoadableDeviceUnion[] => {
    const results: LoadableDeviceUnion[] = devices.map((device) => {
      let result: LoadableDeviceUnion
      const base: LoadableDevice = {
        ...device,
        loading: false,
        loadingTimestamp: timestamp() - DEPARTMENT_NAMES_CACHE_DURATION,
        departments: device.departments.map(dep => ({
          ...dep,
          loading: false,
          loadingTimestamp: timestamp() - DEPARTMENT_PAGES_CACHE_DURATION,
          pages: [],
        })),
      }
      if (device.type === 'REAL_DEVICE') {
        result = { ...base, type: 'REAL_DEVICE' }
      } else if (device.type === 'VIRTUAL_DEVICE') {
        result = { ...base, type: 'VIRTUAL_DEVICE' }
      } else {
        result = {
          ...base,
          type: 'BUSINESS_GROUP',
          devices: device.devices.map(d => ({
            ...d,
            loading: false,
            loadingTimestamp: timestamp() - DEPARTMENT_NAMES_CACHE_DURATION,
            departments: d.departments.map(dep => ({
              ...dep,
              loading: false,
              loadingTimestamp: timestamp() - DEPARTMENT_PAGES_CACHE_DURATION,
              pages: [],
            })),
          })),
        }
      }
      return result
    })
    return results
  }
  const subscriptions: Subscription[] = []
  watch(devices, () => {
    subscriptions.splice(0, subscriptions.length)
      .forEach(s => s.unsubscribe())
    devices.value.forEach(updateDeviceStatus)
  })
  function updateMappingStatus(mapping: LoadableBusinessGroupDevices) {
    mapping.departments.forEach(updateDepartmentStatus)
    subscriptions.push(
      deviceStatusEventStream.value
        .pipe(filter<WebSocketEvent, MappingStatusEvent>((v, _i) => v.type === 'MappingStatus'))
        .pipe(filter(e => e.payload.hexacode === mapping.hexacode
          && e.payload.networkNumber === mapping.networkNumber
        ))
        .subscribe(e => mapping.status = e.payload.status)
    )
  }
  function updateDepartmentStatus(department: LoadableDepartment) {
    subscriptions.push(
      deviceStatusEventStream.value
        .pipe(filter<WebSocketEvent, DepartmentStatusEvent>((v, _i) => v.type === 'DepartmentStatus'))
        .pipe(filter(e => e.payload.hexacode === department.hexacode
          && e.payload.department === department.department
          && e.payload.subDepartment === department.subDepartment
        ))
        .subscribe(e => department.status = e.payload.status)
    )
  }
  function updateDeviceStatus(device: LoadableDeviceUnion) {
    if (device.type === 'BUSINESS_GROUP') {
      device.devices.forEach(updateMappingStatus)
    }
    device.departments.forEach(updateDepartmentStatus)
    subscriptions.push(deviceStatusEventStream.value
      .pipe(filter<WebSocketEvent, DeviceStatusEvent>((v, _i) => v.type === 'DeviceStatus'))
      .pipe(filter(e => e.payload.hexacode === device.hexacode))
      .subscribe((e) => {
        device.status = e.payload.status
        const isOnline = Status[e.payload.status].isOnline
        device.departments.forEach((dep) => {
          if (isOnline) {
            dep.status = Status[dep.status].onlineEquivalent
          } else {
            dep.status = Status[dep.status].offlineEquivalent
          }
        })
      })
    )
  }
  const load = () => {
    if (!isLoading.value) {
      request.value = controller.execute()
    }
  }
  const unload = () => {
    request.value = Promise.resolve([])
    devices.value = []
  }
  /**
   * Apply the configuration map,
   * @param configurationMap - map of hexacode to device configuration
   */
  function updateDeviceConfigurationSettings(configurationMap: DeviceConfigurationMap) {
    devices.value.forEach((d) => {
      const deviceConfigurationMap = configurationMap.get(d.hexacode)
      if (deviceConfigurationMap) {
        d.configuration = deviceConfigurationMap.configuration
        d.departments.forEach((dep) => {
          const departmentConfiguration = deviceConfigurationMap.departments.get(dep.configuration.key)
          if (departmentConfiguration) {
            dep.configuration = departmentConfiguration
          }
        })
        if (d.type === 'BUSINESS_GROUP') {
          d.devices.forEach((linkedDevice) => {
            const linkedDeviceConfiguration = deviceConfigurationMap.devices.get(linkedDevice.configuration.key)
            if (linkedDeviceConfiguration) {
              linkedDevice.configuration = linkedDeviceConfiguration.configuration
              linkedDevice.departments.forEach((dep) => {
                const departmentConfiguration = linkedDeviceConfiguration.departments.get(dep.configuration.key)
                if (departmentConfiguration) {
                  dep.configuration = departmentConfiguration
                }
              })
            }
          })
        }
      }
    })
  }
  whenever(isLoggedIn, load, { immediate: true })
  whenever(() => !isLoggedIn.value, unload)
  watch(
    deviceSortingSettings,
    (n) => {
      sortDevices(devices.value, n.sortMode, n.sortDirection)
    }
  )
  const loadDepartmentNames = (device: LoadableDeviceUnion) => {
    const now = timestamp()
    if (now - device.loadingTimestamp >= DEPARTMENT_NAMES_CACHE_DURATION) {
      device.loading = true
      device.loadingTimestamp = now
      const request: DepartmentNameRequest[] = device.departments.map(d => ({
        key: d.key,
        adf: d.adf,
        hexacode: d.hexacode,
        department: d.department,
        subDepartment: d.subDepartment,
      }))
      return findDepartmentNames(request).then((r) => {
        r.data.forEach((e) => {
          const department = device.departments.find(d => d.key === e.key)
          if (department) {
            department.name = e.label
          }
        })
      }).finally(() => { device.loading = false })
    }
    return Promise.resolve()
  }
  const loadDepartmentPages = (department: LoadableDepartment, force: boolean = false) => {
    if (Status[department.status].isOnline) {
      const now = timestamp()
      if ((now - department.loadingTimestamp >= DEPARTMENT_PAGES_CACHE_DURATION) || force) {
        department.loading = true
        const request: DepartmentPageRequest = {
          adf: department.adf,
          hexacode: department.hexacode,
          rawHexacode: department.rawHexacode,
          department: department.department,
          subDepartment: department.subDepartment,
        }
        return findDepartmentPages(request).then((r) => {
          department.pages = r.data.map<DepartmentPage>(e => ({ ...e, lines: [], loading: false, linesLoadingTime: -1 }))
          department.loadingTimestamp = now
          return department.pages
        }).finally(() => { department.loading = false })
      }
    }
    return Promise.resolve(department.pages)
  }
  const loadDepartmentPageLines = (department: LoadableDepartment, page: DepartmentPage, force: boolean = false, withBuffers: boolean = false) => {
    if (Status[department.status].isOnline) {
      const now = timestamp()
      if ((now - page.linesLoadingTime >= DEPARTMENT_LINES_CACHE_DURATION) || force) {
        page.loading = true
        page.linesLoadingTime = now
        const request: DepartmentPageLinesRequest = {
          adf: department.adf,
          hexacode: department.hexacode,
          rawHexacode: department.rawHexacode,
          department: department.department,
          subDepartment: department.subDepartment,
          page: page.number,
          withBuffers,
        }
        return findDepartmentPageLines(request).then((r) => {
          page.lines = r.data
          return page.lines
        }).finally(() => { page.loading = false })
      }
    }
    return Promise.resolve(page.lines)
  }
  return {
    devices,
    isLoading,
    request,
    load,
    unload,
    loadDepartmentNames,
    loadDepartmentPages,
    loadDepartmentPageLines,
    updateDeviceConfigurationSettings,
  }
})
