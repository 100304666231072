<template>
  <NuxtLayout>
    <div class="d-flex flex-column w-100 h-100 justify-center align-center">
      <h1>Dang</h1>
      <p class="py-2">
        <strong>{{ message }}</strong>
      </p>
      <p>It looks like something broke.</p>
      <p>Sorry about that.</p>
      <v-btn class="mt-8" @click="handleError">Go home</v-btn>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const { error } = defineProps({
  error: Object as () => NuxtError,
})
const message = computed(() => {
  if (error?.statusMessage) {
    return error.statusMessage
  } else if (error?.message) {
    return error.message
  }
  return ''
})
function handleError() {
  clearError({ redirect: '/' })
};
</script>
