import payload_plugin_qLmFnukI99 from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_date_uw7qb7IA4F from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n-date.js";
import vuetify_i18n_H4uUvhyrU1 from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_KAumv2pUni from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import event_bus_S6Iq1iGe5d from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/plugins/event-bus.ts";
import web_worker_LLpRrgN8nz from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/plugins/web-worker.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_date_uw7qb7IA4F,
  vuetify_i18n_H4uUvhyrU1,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  vuetify_no_client_hints_kyYn5AnnAK,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  event_bus_S6Iq1iGe5d,
  web_worker_LLpRrgN8nz,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]