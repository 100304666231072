import axios from 'axios'
import type { AccountSettings, ThemeSettings, TwoFactorAuthenticationSetupKey } from '~/types/AccountSettings'
import type { AlarmEntryUnion, AlarmHistoryEntry } from '~/types/Alarms'
import type { BugReport } from '~/types/BugReport'
import type { AbstractWidgetData, Dashboard, DashboardAndGroupsUnion, DashboardShareToken, DashboardsSortAndGroupData, LinkedDashboard, LinkedDashboardToken, Widget, WidgetDownloadType, WidgetMarkAreaElement, Widgets } from '~/types/Dashboards'
import type { DepartmentNameRequest, DepartmentNameResponse, DepartmentPageLinesRequest, DepartmentPageLinesResponse, DepartmentPageLineUpdateRequest, DepartmentPageRequest, DepartmentPageResponse, DeviceBuffer } from '~/types/Device'
import type { DevicesConfiguration, DeviceUnion } from '~/types/DeviceConfiguration'
import type { FeatureRequest } from '~/types/FeatureRequest'
import type { DomainLoginRequest, DomainMfaLoginRequest, LoginResponse } from '~/types/Login'
import type { Language, PersonalSettings } from '~/types/PersonalSettings'
import type { AccountsPaginationRequest, AccountsPaginationResponse, VapidSubscription } from '~/types/Security'

export const API_URL = window.origin + '/api'
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
api.interceptors.request.use(function (config) {
  const securityStore = useSecurityStore()
  config.headers.Authorization = `Bearer ${securityStore.apiToken}`
  return config
})
api.interceptors.response.use(
  response => response,
  (error) => {
    const securityStore = useSecurityStore()
    if ((error.response && error.response.status === 401) && securityStore.isLoggedIn) {
      securityStore.unload()
      navigateTo('/login')
      // error.response.status === 403
    }
    return Promise.reject(error)
  }
)
export function findTheme() {
  return api.get('/themes')
}
export function login(loginRequest: DomainLoginRequest | DomainMfaLoginRequest) {
  return api.post<LoginResponse>('/login', loginRequest)
}
export function logout() {
  return api.post('/logout')
}
export function findAccountSettings() {
  return api.get<AccountSettings>('/api/v2/account/settings')
}
export function setup2Fa() {
  return api.get<TwoFactorAuthenticationSetupKey>('/api/v2/account/settings/2fa')
}
export function verify2Fa(secret: string, code: string) {
  return api.post<never>('/api/v2/account/verify-2fa', secret, {
    params: {
      code: code,
    },
  })
}
export function updateAccountSettings(settings: PersonalSettings) {
  return api.post<PersonalSettings>('/api/v2/account/settings', settings)
}
export function findAvailableLanguages() {
  return api.get<Language[]>('/api/v2/account/settings/languages')
}
export function findDevices() {
  return api.get<DeviceUnion[]>('/api/v2/devices/')
}
export function findDepartmentNames(body: DepartmentNameRequest[]) {
  return api.post<DepartmentNameResponse[]>('/api/v2/devices/departments/names', body)
}
export function findDepartmentPages(body: DepartmentPageRequest) {
  return api.post<DepartmentPageResponse[]>('/api/v2/devices/departments/pages', body)
}
export function findDepartmentPageLines(body: DepartmentPageLinesRequest) {
  return api.post<DepartmentPageLinesResponse[]>('/api/v2/devices/departments/pages/lines', body)
}
export function submitDepartmentPageLine(body: DepartmentPageLineUpdateRequest) {
  return api.put<never>('/api/v2/devices/departments/pages/lines', body)
}
export function submitBuffer(body: DeviceBuffer) {
  return api.put<never>('/api/v2/devices/departments/pages/lines/buffers', body)
}
export function removeBuffer(id: string) {
  return api.delete<never>(`/api/v2/devices/departments/pages/lines/buffers/${id}`)
}
export function findDeviceAlarms() {
  return api.get<AlarmEntryUnion[]>('/api/v2/alarms/')
}
export function findDeviceAlarmsHistory() {
  return api.get<AlarmHistoryEntry[]>('/api/v2/alarms/history')
}
export function findAccounts(paginationRequest: AccountsPaginationRequest) {
  return api.post<AccountsPaginationResponse>('/api/v2/account/paginate', paginationRequest)
}
export function switchAccount(id: string) {
  return api.post<LoginResponse>(`/api/v2/account/switch/${id}`)
}
export function switchAccountBack() {
  return api.post<LoginResponse>('/api/v2/account/switch-back')
}
export function submitFeatureRequest(featureRequest: FeatureRequest, sendMeACopy: boolean) {
  return api.post<never>('/api/v2/support/feature-request', featureRequest, {
    params: {
      sendMeACopy: sendMeACopy,
    },
  })
}
export function submitBugReport(bugReport: BugReport, sendMeACopy: boolean) {
  return api.post<never>('/api/v2/support/bug-report', bugReport, {
    params: {
      sendMeACopy: sendMeACopy,
    },
  })
}
export function submitThemeSettings(settings: ThemeSettings) {
  return api.post<never>('/api/v2/account/settings/theme', settings)
}
export function submitDevicesConfiguration(configuration: DevicesConfiguration) {
  return api.post<never>('/api/v2/account/settings/devices', configuration)
}
export function findDashboards() {
  return api.get<DashboardAndGroupsUnion[]>('/api/v2/dashboards/')
}
export function deleteDashboard(id: string) {
  return api.delete<never>(`/api/v2/dashboards/${id}`)
}
export function saveDashboard(dashboard: Dashboard) {
  return api.post<Dashboard>('/api/v2/dashboards/', dashboard)
}
export function updateDashboard(dashboard: Dashboard) {
  return api.put<Dashboard>('/api/v2/dashboards/', dashboard)
}
export function setFavorite(id: string) {
  return api.post<never>(`/api/v2/dashboards/${id}/favorite`)
}
export function updateDashboardSortingAndGrouping(sortingAndGroupingData: DashboardsSortAndGroupData) {
  return api.post<DashboardsSortAndGroupData>('/api/v2/dashboards/sorting-and-grouping', sortingAndGroupingData)
}
export function findWidgetData<K extends AbstractWidgetData>(widget: Widget) {
  return api.post<K>('/api/v2/dashboards/widgets', widget)
}
export function downloadWidgetData(request: Widgets, exportType: WidgetDownloadType) {
  return api.post<BlobPart>('/api/v2/dashboards/widgets/download', request, {
    params: {
      exportType: exportType,
    },
    responseType: 'blob',
  })
}
export function downloadSharedWidgetData(linkId: string, widgetId: string, exportType: WidgetDownloadType) {
  return api.post<BlobPart>(`/api/v2/dashboards/share/${linkId}/${widgetId}/download`, undefined, {
    params: {
      exportType: exportType,
    },
    responseType: 'blob',
  })
}
export function updateMarkArea(request: WidgetMarkAreaElement[], dashboard: string, widget: string) {
  return api.post<WidgetMarkAreaElement[]>('/api/v2/dashboards/widgets/mark-area-elements', request, {
    params: {
      dashboard: dashboard,
      widget: widget,
    },
  })
}
export function findDashboardShareTokens() {
  return api.get<DashboardShareToken[]>('/api/v2/dashboards/share')
}
export function saveDashboardShareTokens(dashboardId: string) {
  return api.post<DashboardShareToken>(`/api/v2/dashboards/share/${dashboardId}`)
}
export function deleteDashboardShareTokens(id: string) {
  return api.delete<never>(`/api/v2/dashboards/share/${id}`)
}
export function findDashboardByShareToken(id: string) {
  return api.get<Dashboard>(`/api/v2/dashboards/share/${id}`)
}

export function findLinkedDashboards() {
  return api.get<LinkedDashboard[]>(`/api/v2/dashboards/linked`)
}
export function findLinkedDashboardByLinkedTokenId(tokenId: string) {
  return api.get<LinkedDashboard>(`/api/v2/dashboards/linked/${tokenId}`)
}
export function linkDashboard(tokenId: string) {
  return api.post<LinkedDashboardToken>(`/api/v2/dashboards/linked/${tokenId}`)
}
export function unlinkDashboard(linkId: string) {
  return api.delete<never>(`/api/v2/dashboards/linked/${linkId}`)
}
export function findVapidKey() {
  return api.get<string>('/api/v2/notifications/key')
}
export function subscribeToPushNotifications(subscription: VapidSubscription) {
  return api.post<VapidSubscription>('/api/v2/notifications/subscribe', subscription)
}
export function unsubscribeFromPushNotifications() {
  return api.get<never>('/api/v2/notifications/unsubscribe')
}
export function sendTestPushNotification() {
  return api.get<never>('/api/v2/notifications/test-notification')
}
