
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexI0C29kp5xPMeta } from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/[hexacode]/[department]/[subDepartment]/[page]/index.vue?macro=true";
import { default as _91id_93FuNU8Sw8BBMeta } from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/dashboards/[id].vue?macro=true";
import { default as index5ruKvoMSA4Meta } from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/dashboards/index.vue?macro=true";
import { default as indexVwK1VxzbttMeta } from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/index.vue?macro=true";
import { default as LoginLZOUJ1lFYEMeta } from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/Login.vue?macro=true";
import { default as _91id_93RQWJPfyeQtMeta } from "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/shared/dashboard/[id].vue?macro=true";
export default [
  {
    name: "department-page-view",
    path: "/:hexacode()/:department()/:subDepartment()/:page()",
    meta: indexI0C29kp5xPMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/[hexacode]/[department]/[subDepartment]/[page]/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboards/:id()",
    meta: _91id_93FuNU8Sw8BBMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/dashboards/[id].vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: index5ruKvoMSA4Meta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/dashboards/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexVwK1VxzbttMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/index.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: LoginLZOUJ1lFYEMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/Login.vue")
  },
  {
    name: "shared-dashboard",
    path: "/shared/dashboard/:id()",
    meta: _91id_93RQWJPfyeQtMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/pages/shared/dashboard/[id].vue")
  }
]