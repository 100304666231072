/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAsyncState } from '@vueuse/core'
import { registerTheme } from 'echarts'
import { defineStore } from 'pinia'

export const useThemeStore = defineStore('Theme', () => {
  type ThemeStyle = 'dark' | 'light'
  const domain = window.location.host.split('.')[0]
  const title = domain.charAt(0).toUpperCase() + domain.slice(1)
  const promise = ref<Promise<any>>()
  const { state, execute } = useAsyncState(findTheme().then(r => r.data), {
    name: domain,
    loginLogo: null,
    sidebarLogo: null,
    manifest: null,
    favicons: [],
    theme: {
      dark: {
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        primary: '#2196F3',
        secondary: '#242424',
        success: '#4CAF50',
        warning: '#FB8C00',
        sidebar: '#FFFFFF',
        sidebar_secondary: '#FFFFFF',
      },
      light: {
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        primary: '#1976D2',
        secondary: '#FF5252',
        // secondary: '#424242',
        success: '#4CAF50',
        warning: '#FB8C00',
        sidebar: '#000000de',
        sidebar_secondary: '#000000de',
      },
    },
  }, { immediate: false })
  promise.value = execute()
  const theme = useTheme()
  const loginLogo = computed(() => state.value?.loginLogo)
  const sidebarLogo = computed(() => state.value?.sidebarLogo)
  const useDarkTheme = computed(() => theme.global.current.value.dark)
  promise.value.then(() => {
    Object.keys(state.value.theme.dark).forEach((key) => {
      theme.themes.value.dark.colors[key] = state.value.theme.dark[key]
    })
    Object.keys(state.value.theme.light).forEach((key) => {
      theme.themes.value.light.colors[key] = state.value.theme.light[key]
    })
    theme.themes.value.light.colors.background = '#f8f9fa'
    theme.themes.value.dark.colors.background = '#121212'

    if (localStorage.getItem('theme') === 'dark') {
      updateLocalTheme('dark')
    } else {
      updateLocalTheme('light')
    }

    registerTheme('light', whiteTheme(theme.themes.value.light.colors.primary))
    registerTheme('dark', darkTheme(theme.themes.value.dark.colors.primary))
    useHead({
      title: title,
      meta: [
        { name: 'theme-color', content: state.value.manifest.theme_color },
        { name: 'apple-mobile-web-app-title', content: title },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
        { name: 'msapplication-TileColor', content: state.value.manifest.theme_color },
        { name: 'viewport', content: 'width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0' },
      ],
      link: [
        { rel: 'apple-touch-icon', href: '/api/themes/manifest/favicon/_152x152.png' },
        { rel: 'mask-icon', color: state.value.manifest.theme_color, href: '/api/themes/manifest/favicon/_144x144.png' },
        { rel: 'icon', href: '/api/themes/manifest/favicon/_32x32.png', sizes: '32x32' },
        { rel: 'icon', href: '/api/themes/manifest/favicon/_16x16.png', sizes: '16x16' },
      ],
    })
  })
  function updateLocalTheme(themeStyle: ThemeStyle) {
    theme.global.name.value = themeStyle
    localStorage.setItem('theme', themeStyle)
  }
  return {
    useDarkTheme,
    loginLogo,
    sidebarLogo,
    updateLocalTheme,
  }
})
