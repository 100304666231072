
// @ts-nocheck


export const localeCodes =  [
  "DK",
  "DU",
  "EN",
  "ES",
  "FI",
  "FR",
  "HU",
  "NL",
  "PL",
  "RU",
  "SK"
]

export const localeLoaders = {
  "DK": [{ key: "../i18n/locales/dk.json", load: () => import("../i18n/locales/dk.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_dk_json" */), cache: true }],
  "DU": [{ key: "../i18n/locales/du.json", load: () => import("../i18n/locales/du.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_du_json" */), cache: true }],
  "EN": [{ key: "../i18n/locales/en.json", load: () => import("../i18n/locales/en.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_en_json" */), cache: true }],
  "ES": [{ key: "../i18n/locales/es.json", load: () => import("../i18n/locales/es.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_es_json" */), cache: true }],
  "FI": [{ key: "../i18n/locales/fi.json", load: () => import("../i18n/locales/fi.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_fi_json" */), cache: true }],
  "FR": [{ key: "../i18n/locales/fr.json", load: () => import("../i18n/locales/fr.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_fr_json" */), cache: true }],
  "HU": [{ key: "../i18n/locales/hu.json", load: () => import("../i18n/locales/hu.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_hu_json" */), cache: true }],
  "NL": [{ key: "../i18n/locales/nl.json", load: () => import("../i18n/locales/nl.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_nl_json" */), cache: true }],
  "PL": [{ key: "../i18n/locales/pl.json", load: () => import("../i18n/locales/pl.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_pl_json" */), cache: true }],
  "RU": [{ key: "../i18n/locales/ru.json", load: () => import("../i18n/locales/ru.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_ru_json" */), cache: true }],
  "SK": [{ key: "../i18n/locales/sk.json", load: () => import("../i18n/locales/sk.json" /* webpackChunkName: "locale__home_optilink_data_jenkins_workspace_link_web_portal_frontend_develop_i18n_locales_sk_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "DK",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/dk.json"
      ]
    },
    {
      "code": "DU",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/du.json"
      ]
    },
    {
      "code": "EN",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/en.json"
      ]
    },
    {
      "code": "ES",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/es.json"
      ]
    },
    {
      "code": "FI",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/fi.json"
      ]
    },
    {
      "code": "FR",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/fr.json"
      ]
    },
    {
      "code": "HU",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/hu.json"
      ]
    },
    {
      "code": "NL",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/nl.json"
      ]
    },
    {
      "code": "PL",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/pl.json"
      ]
    },
    {
      "code": "RU",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/ru.json"
      ]
    },
    {
      "code": "SK",
      "files": [
        "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/sk.json"
      ]
    }
  ],
  "defaultLocale": "EN",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "DK",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/dk.json"
      }
    ]
  },
  {
    "code": "DU",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/du.json"
      }
    ]
  },
  {
    "code": "EN",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/en.json"
      }
    ]
  },
  {
    "code": "ES",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/es.json"
      }
    ]
  },
  {
    "code": "FI",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/fi.json"
      }
    ]
  },
  {
    "code": "FR",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/fr.json"
      }
    ]
  },
  {
    "code": "HU",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/hu.json"
      }
    ]
  },
  {
    "code": "NL",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/nl.json"
      }
    ]
  },
  {
    "code": "PL",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/pl.json"
      }
    ]
  },
  {
    "code": "RU",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/ru.json"
      }
    ]
  },
  {
    "code": "SK",
    "files": [
      {
        "path": "/home/optilink/data/jenkins/workspace/link_web-portal-frontend_develop/i18n/locales/sk.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
